<template>
    <div class="notify-area">
        <div class="notify-button" @click="showNotificacoes" v-b-toggle.sidebar-right-notificacoes >
            <i class="fa fa-bell-o qtde-notif-sino">
                <b-badge pill class="qtde-notif" v-if="qtdeNotificacoes > 0" variant="warning">{{qtdeNotificacoes}}</b-badge>
            </i>
        </div>

        <b-sidebar class="menu-not-lateral" id="sidebar-right-notificacoes" title="" width=300px right shadow backdrop z-index="100">
           
            <div class="px-3">                     
                
                <b-button-toolbar>  
                    <b-button-group class="mx-1"> 
                        <b-button @click="limparNotificacoes" variant="link" size="sm">Limprar</b-button>
                    </b-button-group>
                </b-button-toolbar>
                <hr>

                <b-row v-if="qtdeNotificacoes > 0" >
                    <b-col>                        
                        <b-list-group>
                            <b-list-group-item 
                                href="#" @click="exclirNotificacao"
                                v-for="(c, idNotificacao) in notificacoes" v-bind:key="idNotificacao"
                                class="mb-2"
                            > 

                                <div class="container-not-div">
                                    <i :style="`color: ${c.variante}`" class="fa fa-bell-o icone-notificacao"></i>                                  

                                    <div class="texto-div">
                                        <h5>
                                            <i v-if="c.tipo === 'NFSe'" :style="`color: ${c.variante}`" class="fa fa-file-code-o mr-3"></i>
                                            <i v-if="c.tipo === 'Bol'" style="color: red" class="fa fa-barcode mr-3"></i>{{c.tipo === 'NFSe' ? 'Nota Fiscal (Serviço)' : 'Boleto'}}
                                        </h5>
                                        <p>{{c.mensagem}}</p>
                                        <small class="texto-div-datahora">{{c.dataHora}}</small>
                                        <small class="texto-div-datahora" @click="excluirNotificacao(c.idNotificacao)"> - <strong>EXCLUIR</strong></small>

                                    </div>
                                </div>
                                                                                     
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </b-row>                
                
                <b-row v-else>
                    <b-col>
                        <span>Nenhum notificação no momento...</span>
                    </b-col>
                </b-row>          
                
            </div> 
            
        </b-sidebar>
    </div>
</template>

<script>
// import { userKey } from '@/global'
import { mapState } from 'vuex'
import { baseApiUrl } from '@/global'
import axios from 'axios'
const moment = require('moment')

export default {
    name: 'Notifications',
    computed: mapState(['user']),
    data: function() {
        return {
            testes: '',
            retornosNotificados: [],
            notificacoes: [],
            qtdeNotificacoes: 0
        }
    },
    methods: {
        notificacoesRetornos() {  

            setInterval(()=>{
                
                axios['get'](`${baseApiUrl}/contasbancariasretorno`)
                .then(async res => {                   
    
                    if (res.data.length > 0) {
                        const notif = [ ...res.data ]
                        this.criarNotificacao(notif, 'Bol', false) 
                    }
                })
                .catch(err=>{
                    this.criarNotificacao(err, 'Bol', true)  
                })


            }, (60 * 1000) * 2)

        },

        limparNotificacoes() {
            this.notificacoes = []
            this.qtdeNotificacoes = 0

            localStorage.setItem('__lancamentos_notificacoes', 
                JSON.stringify([])
            )
        },

        excluirNotificacao(id) {
            this.notificacoes = this.notificacoes.filter(
                (notificacao) => notificacao.idNotificacao !== id
            )
            
            this.qtdeNotificacoes = this.notificacoes.length
        },

        notificacoesNFSe() {
            const n = localStorage.getItem('__lancamentos_notificacoes') 
            this.notificacoes = n ? JSON.parse(n) : []
            this.qtdeNotificacoes = this.notificacoes.length

            setInterval(()=>{
                
                axios.get(`${baseApiUrl}/gerarnfse/verificar`, {
                    params: {
                        consultaIndividual: 'N'
                    }
                })
                .then(async res => {
                    if (res.data.length > 0) {
                        const notif = [ ...res.data ]
                        this.criarNotificacao(notif, 'NFSe', false)                        
                    }
                }) 
                .catch(err => {
                    this.criarNotificacao(err, true)
                })

            }, (60 * 1000) * 1)

        },

        criarNotificacao(notify, tipo, e) {

            if (e) {
                this.notificacoes.unshift({
                    tipo: tipo,
                    variante: '#d6345d',
                    mensagem: notify,
                    dataHora: moment().format('DD/MM/yy HH:mm:ss')
                }) 

            } else {

                for (let item of notify) {
                    if (!this.notificacoes.some(e => e.idNotificacao === item.idNotificacao && e.statusAtual === item.statusAtual)) {  
                        item.dataHora= moment().format('DD/MM/yy HH:mm:ss')   
                        this.notificacoes.unshift(item)  
                    }
                }
            }

            this.qtdeNotificacoes = this.notificacoes.length

            localStorage.setItem('__lancamentos_notificacoes', 
                JSON.stringify(this.notificacoes)
            )
        },

        verificarStatusBoletos() {  

            setInterval(()=>{    

                axios['get'](`${baseApiUrl}/gerarboleto/verificar`)

            }, (60 * 1000) * 2)

        },

        showNotificacoes() {
        }
    },
    mounted() {
        this.notificacoesRetornos(),
        this.notificacoesNFSe(),     
        this.verificarStatusBoletos()       
    }
}
</script>

<style>
    .notify-area {
        align-self: initial;
        height: 100%;        
    }

    .notify-area:hover {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    .notify-button {
        display: flex;
        font-size: 1.2rem;
        align-items: center;
        padding: 27px 20px;
    }  

    .qtde-notif {
        font-size: 0.7rem !important;
        margin-left: -5px !important;
    }

    .menu-not-lateral { 
        z-index: 200 !important;
        display: flex !important;
        position:  absolute !important;
        overflow-y: hidden !important;
    }    

    .b-sidebar-body {
        overflow-x: hidden !important;
    }

    .b-sidebar-body::-webkit-scrollbar {
        width: 4px; 
    }
    .b-sidebar-body::-webkit-scrollbar:hover {
        width: 10px; 
    }

    .b-sidebar-body::-webkit-scrollbar-track {
        background: transparent; 
        padding: 2px;
    }

    .b-sidebar-body::-webkit-scrollbar-thumb {
        background-color: #6ca7ff;
        border-radius: 10px;
    }

    .icone-notificacao {
        font-size: 5rem !important;
    }
   
    
    .container-not-div {
        margin: 0;
        position: relative;
    }

    .icone-notificacao {
        margin: -15px -25px 0 0 ;
        position: absolute;
        top: 0;
        right: 0;
        rotate: 40deg;
        color: #2a71db15 !important;
    }

    .container-not-div .texto-div {
        position: relative;
    }

    .texto-div-datahora {
        font-size: 0.7rem;
        color: #6e6e6e !important;
    }

    
</style>
