<template>
    <div class="dre-caixas">  
        <b-card class="cadastros-card-fluxo">            
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group prepend="Filial">
                                <b-form-select style="width: 200px; margin-right: 10px;" id="conta-bancaria" v-model="dreFilial" :options="filiais"/>    
                            </b-input-group> 

                            <b-input-group>                                                               
                                <b-form-radio-group
                                    class="mr-2"
                                    id="btn-radios-tipo-dre"
                                    v-model="tipoDre"
                                    :options="optionsTipo"
                                    button-variant="outline-secondary"
                                    name="radios-btn-tipo-dre"
                                    buttons
                                ></b-form-radio-group>
                            </b-input-group>

                            <b-input-group prepend="Período">
                                <b-form-input type="date" v-model="dreDataInicial"></b-form-input>
                                <b-form-input type="date" v-model="dreDataFinal"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="relatorio" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-button-group> 
                    </b-button-toolbar>
                </b-col>    
            </b-row> 
            <b-row align-v="center" style="margin-top: 0">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar> 
                        <b-button-group class="mx-1">
                            <b-input-group>                                                                                           
                                <b-form-radio-group
                                    class="mr-2"
                                    id="btn-radios-tipo-dre"
                                    v-model="regime"
                                    :options="optionsRegime"
                                    button-variant="outline-secondary"
                                    name="radios-btn-tipo-dre"
                                    buttons
                                ></b-form-radio-group>
                            </b-input-group>                            
                        </b-button-group> 

                        <b-input-group prepend="Filial conforme:" v-if="dreFilial > 0 && regime === 'CX'">                                                                                           
                            <b-form-radio-group
                                class="mr-2"
                                id="btn-radios-tipo-dre"
                                v-model="tipoFilial"
                                :options="optionsTipoFilial"
                                button-variant="outline-secondary"
                                name="radios-btn-tipo-dre"
                                buttons
                            ></b-form-radio-group>
                        </b-input-group>  
                    </b-button-toolbar>
                </b-col>    
            </b-row> 
            <hr>  

            <b-row v-if="labelsDre.length > 0" align-v="center" class="mb-3">
                <b-col md="12" sm="12" class="d-flex justify-content-md-center"> 
                    <div>
                        <b-input-group>
                            <b-button 
                                class="botoes-de-impressao-download"  
                                variant="info" 
                                @click="printPage" 
                                v-b-tooltip.hover.bottomleft="'Imprimir'"
                            > 
                                <i class="fa fa-print button-icons"></i>
                            </b-button> 

                            <b-button  
                                class="botoes-de-impressao-download" 
                                variant="danger" 
                                @click="salvarPdf('dre-render-pdf')" 
                                v-b-tooltip.hover.bottomleft="'Baixar PDF'"
                            >
                                <i class="fa fa-file-pdf-o button-icons"></i>
                            </b-button>                                 

                            <b-button 
                                class="botoes-de-impressao-download"  
                                variant="success" 
                                @click="salvarExcel()" 
                                v-b-tooltip.hover.bottomleft="'Exportar para Excel'"
                            > 
                                <i class="fa fa-table button-icons"></i>
                            </b-button> 

                        </b-input-group>
                    </div>                      

                </b-col>
            </b-row>           
                      
        
            <div v-if="relatorioDRE != []" style="width: 100%" class="d-flex justify-content-md-center">
                <section class="pdf-item">
                    <div class="myStyle" >  
                        <div id="dre-render-pdf" class="containerPdf" style="width: 100%" v-if="labelsDre.length > 0"> 
                            
                            <div>
                                <h3 class="mt-3">{{configRel.param.titulo}}</h3>                                 
                                <h6 class="text-secondary">{{configRel.param.subtitulo}}</h6> 
                                <h5 class="text-secondary">{{configRel.param.subtitulo2}}</h5> 
                                <hr><br>
                            </div>   

                            <div>
                                <div v-for="(agrupador, index) in dadosRedistribuidos" :key="index" >      

                                    <h5><strong>{{ agrupador.agrupador }}</strong></h5>

                                    <b-table v-if="agrupador.totais" :items="agrupador.totais" :fields="totaisFields" small hover>
                                        <template #head()="data">
                                            <span style="font-size: 0.9rem; text-align: right; margin: 0 0 -25px 0" class="text-info d-block text-end"><strong>{{ data.label }}</strong></span><br>
                                        </template>

                                        <template #cell()="data">
                                            <span v-if="data.field.label !== ''">{{ separarPorEspaco(data.item[data.field.key], 0) }}</span><br>
                                            <span v-if="data.field.label !== ''" style="font-size: 0.8rem" class="text-secondary">{{ separarPorEspaco(data.item[data.field.key], 1) }}</span>
                                            
                                        </template>
                                    </b-table>  
                                    
                                    <b-table v-else :items="tipoDre === 'R' ? [] : agrupador.dados" :fields="fields" hover small :fixed="labelsDre.length > 6">
                                        <template #head()="data">
                                            <div v-if="tipoDre !== 'A'">
                                                <span style="font-size: 0.9rem; text-align: right; margin: 0 0 -20px 0" class="text-info d-block text-end"><strong>{{ data.label }}</strong></span><br>
                                                <span style="font-size: 0.85rem; text-align: right; margin: 0 0 -20px 0" class="d-block text-end">{{ separarPorEspaco(agrupador[data.field.key], 0) }}</span><br>
                                                <span style="font-size: 0.75rem; text-align: right;" class="text-secondary d-block text-end">{{ separarPorEspaco(agrupador[data.field.key], 1) }}</span>
                                            </div>
                                            <div v-else>
                                                <span style="font-size: 0.9rem" class="text-info"><strong>{{ data.label }}</strong></span><br>
                                                <span style="font-size: 0.85rem">{{ data.field.key === 'total' ? agrupador[data.field.key].toLocaleString('pt-br', {minimumFractionDigits: 2}) : '' }}</span><br>
                                                <span style="font-size: 0.75rem" class="text-secondary">{{ data.field.key === 'total' ? '('+agrupador.porc+')' : '' }}</span>
                                            </div>
                                        </template>

                                        <template #cell()="data">
                                            <span v-if="data.field.key === 'id_plano_conta'">{{ data.item.id_plano_conta }}</span>
                                            <span v-if="data.field.key === 'descricao'">{{ data.item.descricao }}</span>
                                            
                                            <div v-if="tipoDre !== 'A'">
                                                <span v-if="data.field.label !== ''" style="font-size: 0.85rem">{{ separarPorEspaco(data.item[data.field.key], 0) }}</span><br>
                                                <span v-if="data.field.label !== ''" style="font-size: 0.75rem" class="text-secondary">{{ separarPorEspaco(data.item[data.field.key], 1) }}</span>
                                            </div>
                                            <div v-else>
                                                <span v-if="data.field.label !== ''" style="font-size: 0.85rem">{{ data.item.total.toLocaleString('pt-br', {minimumFractionDigits: 2}) }}</span><br>
                                                <span v-if="data.field.label !== ''" style="font-size: 0.75rem" class="text-secondary">{{ '('+data.item.porc+')' }}</span>
                                            </div>
                                        </template>

                                        <template #row-details="row">
                                            <div style="padding-left: 40px">
                                                <b-table :items="row.item.registros" hover small responsive="sm" :fields="fieldsDetalhes" borderless thead-class="d-none" table-class="small-text">
                                                </b-table>
                                            </div>                                            

                                        </template>
                                    </b-table>

                                </div>
                            </div>                                       

                        </div>

                    </div>

                </section>   

            </div>

        </b-card>   
       
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
const moment = require('moment')
import axios from 'axios'
import html2pdf from "html2pdf.js"
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'

export default {
    name: 'Dre',
    components: {  },
    data: function() {
        return {
            dadosRedistribuidos: [], // será preenchido com os dados redistribuídos
            fields: [],
            fieldsDetalhes: [
                {key: 'data_competencia', label: 'Data', sortable: false},
                {key: 'num_docto', label: 'Docto', sortable: false},
                {key: 'razao_social', label: 'Nome', sortable: false},
                {key: 'valor', label: 'Valor', sortable: false, tdClass: 'text-right'},
                {key: 'porc', label: '', sortable: false, tdClass: 'text-right'}
            ],
            totaisFields: [],
            labelsDre: [],

            headers: [
                {
                    text: '',
                    align: 'start',
                    value: 'AGRUPADOR', //AGRUPADOR
                    groupable: false,
                },
                { text: '', value: 'CONTA', align: 'left' },
                { text: '', value: 'descricao', align: 'left' },
                { text: '', value: 'valor', align: 'right' },
                { text: '', value: 'porc', align: 'right' },
            ],
            relacao: [],
            agrupador: ['AGRUPADOR',2], //AGRUPADOR
            ordenar: '',
            configRel: { 
                param: {
                    titulo: 'DRE - Demonstrativo de resultados',
                    subtitulo: `Período: ${moment(this.dreDataInicial).format('DD/MM/YYYY')} à ${moment(this.dreDataFinal).format('DD/MM/YYYY')}`,
                    arquivo: 'dre-demonstrativo-de-resultados',
                    datainicial: this.dreDataInicial,
                    datafinal: this.dreDataFinal,
                    
                    page: 1,
                    limit: 10,
                    //order: 'asc'
                } 
            } , 
            // DRE
            tipoDre: 'S',
            tipoFilial: 'CX',
            regime: 'CP',
            dreFilial: 0,
            filiais: [],
            filialX: {},
            relatorioDRE: [],
            dreDataInicial: moment().add(-1, 'M').format('YYYY-MM-01'),
            dreDataFinal: moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
            optionsTipo: [
                { text: 'Sintético', value: 'S' },
                { text: 'Analítico', value: 'A' },
                { text: 'Resumido', value: 'R' }
            ],
            optionsRegime: [
                { text: 'Regime de Competência', value: 'CP' },
                { text: 'Regime de Caixa', value: 'CX' },
            ],
            optionsTipoFilial: [
                { text: 'Caixa', value: 'CX' },
                { text: 'Fatura', value: 'FT' },
            ]
            
        }
    },
    methods: {
        salvarExcel() {
            let dadosTratados = [];

            // Iterar sobre cada agrupador
            this.relatorioDRE.forEach(agr => {

                if (this.tipoDre !== 'A') {
                    if(agr.dados) {
                        let soMeses = { ...agr }
                        delete soMeses.dados
                        delete soMeses.agrupador
    
                        let ordenado = { 
                            "agrupador": agr.agrupador,
                            "id_plano_conta": "",
                            "descricao": "",
                            ...soMeses
                        }
    
                        if (this.tipoDre === 'R') {
                            delete ordenado.id_plano_conta
                            delete ordenado.descricao
                        }
    
                        dadosTratados.push({...ordenado})
    
                        if (this.tipoDre === 'S') {
                            for (const d of agr.dados) {
        
                                delete d.receita_despesa
                                delete d.tipo_dre
        
                                dadosTratados.push({
                                    "agrupador": "",
                                    ...d
                                })
                            }
                        }                
    
                    } else {
                        dadosTratados.push({
                            "agrupador": agr.agrupador,
                            "id_plano_conta": "",
                            "descricao": "",
                            ...agr.totais[0]
                        })
                    }
                } else {
                     
                    let ordenado = { 
                        "agrupador": agr.agrupador,
                        "id_plano_conta": "",
                        "descricao": "",
                        "total": agr.total,
                        "porc": agr.porc                           
                    }

                    dadosTratados.push({...ordenado})

                    if(agr.dados) {   
                        
                        for (const d of agr.dados) {
    
                            delete d.receita_despesa
                            delete d.tipo_dre
    
                            dadosTratados.push({
                                "agrupador": "",
                                "id_plano_conta": d.id_plano_conta,
                                "descricao": d.descricao,
                                "total": d.total,
                                "porc": d.porc  
                            })

                            dadosTratados.push({
                                "agrupador": "",
                                "id_plano_conta": 'data',
                                "descricao": "docto/razao_social",
                                "total": "valor",
                                "porc": "porc"  
                            })

                            for (const r of d.registros) {
                                
                                dadosTratados.push({
                                    "agrupador": "",
                                    "id_plano_conta": r.data_competencia,
                                    "descricao": `(${r.num_docto}) ${r.razao_social}`,
                                    "total": r.valor,
                                    "porc": r.porc  
                                })
                            }
                        }               
    
                    } 

                }


            });

            // Criar uma nova planilha com os dados processados
            const ws = XLSX.utils.json_to_sheet(dadosTratados);

            // Criar um novo livro de trabalho e adicionar a planilha
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Dados');

            // Converter o livro de trabalho para um arquivo Excel
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Definir o título do arquivo com base no tipo de DRE
            let tipoDreImpresso = '';

            switch (this.tipoDre) {
                case 'S':
                    tipoDreImpresso = 'Sintético';
                    break;
                case 'R':
                    tipoDreImpresso = 'Resumido';
                    break;                
                default:
                    tipoDreImpresso = 'Analítico';
                    break;
            }

            const titulo = `DRE_${moment(this.dreDataInicial).format('DD-MM-YYYY')}_ATÉ_${moment(this.dreDataFinal).format('DD-MM-YYYY')}_(${tipoDreImpresso})`;
            saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${titulo}.xlsx`);
        },
                
        printPage() {
            const printWindow = window.open('', '_blank');
            const content = document.getElementById('dre-render-pdf').innerHTML;  
            
            let tipoDreImpresso = ''

            switch (this.tipoDre) {
                case 'S':
                    tipoDreImpresso = 'Sintético'
                    break;
                case 'R':
                    tipoDreImpresso = 'Resumido'
                    break;                
                default:
                    tipoDreImpresso = 'Analítico'
                    break;
            }

            const titulo = `DRE_${moment(this.dreDataInicial).format('DD-MM-YYYY')}_ATÉ_${moment(this.dreDataFinal).format('DD-MM-YYYY')}_(${tipoDreImpresso})`

            printWindow.document.write(`
            <html>
                <head>
                    <title>${titulo}</title>
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">  
                    <style>
                        .small-text {
                            font-size: 0.75rem;
                        }
                    </style>                  
                </head>
                <body>
                    <div>${content}</div>
                </body>
            </html>
            `);

            printWindow.document.close();

            // Aguarde o carregamento do conteúdo e, em seguida, imprima
            printWindow.onload = function() {
                printWindow.print();
                printWindow.close();                
            };
        },
        separarPorEspaco(valor, i) {
            if (valor && typeof(valor) === 'string') {
                const item = valor.split(" "); return item[i] 
            }
            
        },
        getMesesFields(lbl) {
            
            // let labels = []
            function formatarMes(mes) {
                const mesNumero = parseInt(mes, 10);
                if (mesNumero >= 1 && mesNumero <= 9) {
                    return mesNumero.toString().padStart(2, '0');
                }
                return mes;
            }
            
            for(const l of lbl) {
                const [m, a] = l.split("/")
                const novaData = `${formatarMes(m)}/${a}`
                this.fields.push({ key: l, label: novaData, tdClass: 'text-right' })
                this.totaisFields.push({ key: l, label: novaData, tdClass: 'text-right' })
            }
        },
        formatTotais(totais) {
            return Object.keys(totais).map(mes => ({ mes, valor: totais[mes] }));
        },

        relatorio: async function() {

            let filial = ''

            this.filiais.forEach( obj => {
                if (obj.value === this.dreFilial) {
                    filial = obj.text
                } 
            }) 

            let tipoDreImpresso = ''
            let tipoDreRegime = ''
            let tipoDreFilial = ''

            tipoDreRegime = this.regime === 'CX' ? 'Regime de Caixa' : 'Regime de Competência'
            tipoDreFilial = this.tipoFilial === 'CX' ?  'Filial dos caixas' : 'Filial das faturas'

            switch (this.tipoDre) {
                case 'S':
                    tipoDreImpresso = 'Sintético'                    
                    break;
                case 'R':
                    tipoDreImpresso = 'Resumido'
                    break;                
                default:
                    tipoDreImpresso = 'Analítico'
                    break;
            }            
            
            this.configRel.param = {  
                titulo: `DRE - Demonstrativo de resultados (${tipoDreImpresso})`,
                subtitulo2: `${tipoDreFilial}: ${filial}`,
                subtitulo: `Período: ${moment(this.dreDataInicial).format('DD/MM/YYYY')} à ${moment(this.dreDataFinal).format('DD/MM/YYYY')} (${tipoDreRegime})`,
                arquivo: 'dre-demonstrativo-de-resultados',
                datainicial: this.dreDataInicial,
                datafinal: this.dreDataFinal,
                idfilial: this.dreFilial,
                
                page: 1,
                limit: 10,
                //order: 'asc'
            }           

            const url = `${baseApiUrl}/${this.tipoDre !== 'A' ? 'dre' : 'dreanalitico'}/`  
            await axios.get(url, {
                params: {
                    datainicial: this.configRel.param.datainicial,
                    datafinal: this.configRel.param.datafinal,
                    tipo: this.tipoDre,
                    filial: this.dreFilial,
                    regime: this.regime,
                    tipoFilial: this.tipoFilial
                }
            }).then(res => {
                this.relatorioDRE = res.data.dados
                this.labelsDre = res.data.intervaloMeses 

                this.totaisFields = []
                this.fields = []

                this.fields = [
                    { key: 'id_plano_conta', label: '', thStyle: { width: '50px' } },
                ]
                if (this.labelsDre.length > 6) {
                    this.fields.push({ key: 'descricao', label: ''})
                } else {
                    this.fields.push({ key: 'descricao', label: '', thStyle: { width: '100%' } })
                }

                if (this.tipoDre !== 'A') {
                    this.getMesesFields(res.data.intervaloMeses)                
                } else {
                    this.fields.push({ key: 'total', label: 'Total' })
                    this.totaisFields.push({ key: 'total', label: 'Total' })

                }
                
                this.dadosRedistribuidos = res.data.dados


            }).catch(err => {
                showError(err)
            })           
            
        },
        moedaBr(valor) {
            return valor.toLocaleString('pt-br', {minimumFractionDigits: 2})
        },        

        salvarPdf(id){            
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            let tipoDreImpresso = ''

            switch (this.tipoDre) {
                case 'S':
                    tipoDreImpresso = 'Sintético'
                    break;
                case 'R':
                    tipoDreImpresso = 'Resumido'
                    break;                
                default:
                    tipoDreImpresso = 'Analítico'
                    break;
            }

            const titulo = `DRE_${moment(this.dreDataInicial).format('DD-MM-YYYY')}_ATÉ_${moment(this.dreDataFinal).format('DD-MM-YYYY')}_(${tipoDreImpresso})`

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.1,0.1,0.1,0.1],
                filename: `${titulo}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: this.labelsDre.length > 6 ? 'landscape' : 'portrait', unit: 'in', format: 'a4', compressPDF: true}
                // jsPDF: {orientation: this.labelsDre.length > 4 ? 'landscape' : 'portrait', unit: 'in', format: 'a4', compressPDF: true}
                // jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },
        
        loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
                this.filiais.unshift({value: 0, text: 'TODAS' })
            })
            .catch(err => {
                showError(err)
            }) 
        },
    
    },
    mounted() {
        this.loadFiliais()
    },
    watch: {
        dreFilial() {
            this.relatorio()
        },
        tipoDre() {
            this.relatorio()
        },
        regime() {
            this.relatorio()
        },
        tipoFilial() {
            this.relatorio()
        },
    },
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .opcoes-faturar {
        display: flex;
        align-items: center;
        width: 200px;
        padding: 25px;
        background-color: beige;
    }

    .opcoes-faturar:hover {
        background-color: rgb(197, 197, 197);
    }

    .opcoes-faturar-f {
        width: 30%;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }









    .containerPdf {
        width: 100%;
    }

    .b-table {
        width: 100%; /* Ajusta a tabela para a largura do contêiner */
        table-layout: auto; /* Permite que a tabela se ajuste automaticamente */
    }

    .b-table thead th, .b-table tbody td {
        word-wrap: break-word; /* Quebra de palavra para evitar que o texto saia da célula */
        overflow-wrap: break-word; /* Quebra de palavra para evitar que o texto saia da célula */
    }

    .scaled {
        transform: scale(0.8); /* Ajuste a escala conforme necessário */
        transform-origin: top left; /* Para manter a posição correta */
    }

    .table {
        width: 100%; /* Faz a tabela usar toda a largura do container */
        /* table-layout: fixed; Garante que as colunas tenham larguras fixas */
    }

    .small-text {
        font-size: 0.75rem; /* Ajuste o tamanho conforme necessário */
    }
    


    
        
    



</style>