<template>
    <div class="contas-pagar">
        <div class="acoesTopo">             
            <b-button  class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.bottomleft="'Cancelar'"
                @click="reset">
                    <i class="fa fa-times button-icons-acoes"></i>
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'" v-b-tooltip.hover.topleft="'Excluir'"
                @click="remove">
                    <i class="fa fa-trash button-icons-acoes"></i>
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" v-b-tooltip.hover.bottomleft="'Salvar'"
                @click="save">
                    <i class="fa fa-check button-icons-acoes"></i>
            </b-button>                        
            <b-button variant="success" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Opções de Faturas'"
                @click="opcoesFaturas">
                    <i class="fa fa-cogs button-icons-acoes"></i>
            </b-button> 
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Relatórios'"
                @click="loadRelFatura()">
                    <i class="fa fa-file-text-o button-icons-acoes"></i>
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Nova Fatura'"
                @click="novaFatura">
                    <i class="fa fa-plus button-icons-acoes"></i>
            </b-button> 
        </div>

        <div class="containerRoll" v-if="show && !gerarFatura && !id_lanc">

            <b-card class="cadastros-card-pesquisa sticky-card-fat-pag">
                <b-row >                

                    <b-col md="4" sm="12" class="radio-consulta">
                        <b-form-group label-for="localizar">
                            <p class="entre-datas">Nome, Docto, CPF ou CNPJ</p> 
                            <b-form-input id="localizar" type="search"
                                size="sm"
                                :formatter="upperCase"
                                v-model="consulta.parcial"
                                style="height: 35px !important;"
                                placeholder="Informe Nome, Docto, CPF ou CNPJ"
                            />
                        </b-form-group> 

                        <b-form-group label-for="conta-filial-sel">
                            <p class="entre-datas">Filial</p> 
                            <b-form-select size="sm" id="conta-filial-sel" v-model="consulta.filial" :options="filiais"/>
                        </b-form-group>                                        

                    </b-col>  

                    <b-col md="3" sm="12" class="radio-consulta">
                        <b-form-group>
                            <p class="entre-datas">Competência</p> 
                            <datepicker
                                style="width: 100%;"
                                v-model="intervaloComp"
                                type="date"
                                range 
                                format="DD/MM/YYYY"   
                                placeholder="Selecionar intervalo de datas"
                            ></datepicker>
                        </b-form-group>

                        <b-form-group label-for="conta-filial-sel">
                            <p class="entre-datas">Despesa</p> 
                            <b-form-select size="sm" id="conta-filial-sel" v-model="consulta.idPlanoConta" :options="planoContas"/>
                        </b-form-group> 
                    </b-col>

                    <b-col md="3" sm="12" class="radio-consulta">
                        <b-form-group>
                            <p class="entre-datas">Vencimento</p> 
                            <datepicker
                                style="width: 100%;"
                                v-model="intervaloVenc"
                                type="date"
                                range 
                                format="DD/MM/YYYY"   
                                placeholder="Selecionar intervalo de datas"
                            ></datepicker>
                        </b-form-group>

                        <b-form-checkbox-group
                            style="margin-top: 5px"
                            id="checkbox-status"
                            v-model="consulta.status"
                            :options="consulta.opcoes"
                            name="chk-status"
                        ></b-form-checkbox-group>  
                    </b-col>

                    <b-col md="2" sm="12" class="radio-consulta d-flex justify-content-end">
                        <b-button 
                            class="botao-consulta" 
                            variant="outline-info" 
                            size="lg"
                            @click="novaConsulta"
                        >
                            <i class="fa fa-search button-icons"></i>
                        </b-button>
                    </b-col>               

                </b-row>
            </b-card> 

            <b-table 
                v-if="show && !gerarFatura && !id_lanc"
                :items="contasPagar" 
                :fields="fields"             
                hover 
                sort-icon-left 
                small 
                striped 
                borderless                                
                responsive
            > 

                <template v-slot:cell(status)="{ value }" >
                    <span>
                        <div style="width: 70px"><strong>{{value}}</strong></div>                  
                    </span>
                </template>
                
                <template v-slot:cell(actions)="data">
                    <div  class="d-flex justify-content-end">
                        <b-button size="sm" variant="primary" @click="loadContaPagar(data.item, 'save')" class="mb-1 mr-1" v-b-tooltip.hover.bottomleft="'Editar'">
                            <i class="fa fa-pencil"></i>
                        </b-button>
                        <b-button size="sm" variant="secondary" @click="loadDetalhes(data.item)" class="mb-1 mr-1" v-b-tooltip.hover.bottomleft="'Detalhes'">
                            <i class="fa fa-info"></i>
                        </b-button>
                        <b-button size="sm" variant="danger" @click="loadContaPagar(data.item, 'remove')" class="mb-1" v-b-tooltip.hover.bottomleft="'Excluir'">
                            <i class="fa fa-trash m-1"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>        

            <b-pagination 
                align="center" 
                size="md" 
                v-model="page"  
                :total-rows="count" 
                :per-page="limit" 
                first-number 
                last-number>
            </b-pagination> 
        </div>
        

        <b-card class="cadastros-card-corpo"  v-if="show && contaPagar.id_conta_pagar">
            <h4># {{contaPagar.id_conta_pagar}}</h4>
        </b-card>

        <b-card class="cadastros-card-corpo" v-if="!show || gerarFatura || id_lanc">
            <b-form>
                <input id="conta-pagar-id" type="hidden" v-model="contaPagar.id_conta_pagar" />

                <b-row v-if="!gerarFatura">
                    <b-col md="3" sm="12">
                        <b-form-group label="Competência" label-for="data-competencia-conta-pagar">
                            <b-form-input id="data-competencia-conta-pagar" type="date"
                                v-model="contaPagar.data_competencia"
                                :readonly="mode === 'remove'" required/>
                        </b-form-group>
                    </b-col>

                    <b-col md="6" sm="12">    
                        <b-form-group label="Pessoa" label-for="contrato-pessoa">
                            <vue-single-select
                                id="contrato-pessoa"
                                placeholder="Procurar"
                                v-model="pessoaSelecionada"
                                :options="pessoas"
                                option-key="value"
                                option-label="text"
                            ></vue-single-select>
                        </b-form-group>
                    </b-col> 

                    <b-col md="3" sm="12">
                        <b-form-group label="Valor" label-for="valor-conta-pagar-m">
                            <money v-model="contaPagar.valor" v-bind="money" class="form-control"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="4" sm="12">
                        <b-form-group label="Data Docto" label-for="data-docto-conta-pagar">
                            <b-form-input id="data-docto-conta-pagar" type="date"
                                v-model="contaPagar.data_documento"
                                :readonly="mode === 'remove'" required/>
                        </b-form-group>
                    </b-col>   
                    <b-col md="4" sm="12">
                        <b-form-group label="Nº Docto" label-for="num-docto-conta-pagar">
                            <b-form-input id="num-docto-conta-pagar" type="text"
                                v-mask="mascaras.docto"
                                :formatter="upperCase"
                                v-model="contaPagar.num_docto" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group>
                    </b-col>                 
                </b-row>

                <b-row v-if="!gerarFatura">                     
                    <b-col md="12" sm="12">
                        <b-form-group label="Descrição" label-for="descricao-conta-pagar">
                            <b-form-textarea
                                :state="limiteCaracteres(contaPagar.descricao, 200)"
                                id="descricao-conta-pagar"
                                placeholder="Informe uma descrição de até 200 caracteres..."
                                v-model="contaPagar.descricao"
                                rows="3"
                            ></b-form-textarea>
                            <span class="frase-erro" v-if="!limiteCaracteres(contaPagar.descricao, 200)" >Excedeu a quantidade máxima de caracteres</span>
                        </b-form-group>
                    </b-col> 
                </b-row>

                <b-card header="Fiscal" header-bg-variant="info" header-text-variant="white" v-if="!gerarFatura && false">                    
                    <b-row>
                        <b-col md="3" sm="12">
                            <b-form-group label="Nº Docto" label-for="num-docto-conta-pagar">
                                <b-form-input id="num-docto-conta-pagar" type="text"
                                    v-mask="mascaras.docto"
                                    :formatter="upperCase"
                                    v-model="contaPagar.num_docto" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Série" label-for="serie-conta-pagar">
                                <b-form-input id="serie-conta-pagar" type="text"
                                    v-mask="mascaras.serie"
                                    :formatter="upperCase"
                                    v-model="contaPagar.serie" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Modelo" label-for="modelo-conta-pagar">                                
                                <b-form-input id="modelo-conta-pagar" type="text"
                                    v-mask="mascaras.mod"
                                    :formatter="upperCase"
                                    v-model="contaPagar.modelo" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="CFOP" label-for="cfop-conta-pagar">
                                <b-form-input id="cfop-conta-pagar" type="text"
                                    v-mask="mascaras.cfop"
                                    :formatter="upperCase"
                                    v-model="contaPagar.cfop" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col> 
                    </b-row>

                    <b-row>
                        <b-col md="7" sm="12">
                            <b-form-group label="Chave NF" label-for="chave-nf-conta-pagar">
                                <b-form-input id="chave-nf-conta-pagar" type="text"
                                    v-mask="mascaras.nf"
                                    :formatter="upperCase"
                                    v-model="contaPagar.chave_nf" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="5" sm="12">
                            <b-form-group label="Protocolo" label-for="protocolo-conta-pagar">
                                <b-form-input id="protocolo-conta-pagar" type="text"
                                    v-mask="mascaras.prot"
                                    :formatter="upperCase"
                                    v-model="contaPagar.protocolo" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card> 
                <br> 

                <b-tabs content-class="mt-3">
                    <b-tab title="Financeiro" active>
                        <b-row> 
                            <b-col md="4" sm="12" v-if="!gerarFatura">
                                <b-form-group label="Plano de Contas" label-for="receber-plano-conta">
                                <vue-single-select
                                    id="receber-plano-conta"
                                    placeholder="Procurar"
                                    v-model="planoSelecionado"
                                    :options="planoContas"
                                    option-key="value"
                                    option-label="text"
                                    :get-option-description="label"
                                    :get-option-value="value"
                                ></vue-single-select>
                            </b-form-group>
                            </b-col>                      
                            <b-col md="4" sm="12">
                                <b-form-group label="Forma" label-for="receber-forma">
                                    <vue-single-select
                                        id="receber-forma"
                                        placeholder="Procurar"
                                        v-model="formaSelecionada"
                                        :options="formas"
                                        option-key="value"
                                        option-label="text"
                                        :get-option-description="label"
                                        :get-option-value="value"
                                    ></vue-single-select>
                                </b-form-group>
                            </b-col>
                            
                            <b-col md="4" sm="12">
                                <b-form-group label="Condição" label-for="conta-pagar-condicao">
                                    <b-input-group>                                        
                                        <vue-single-select
                                            class="btn-condicao"
                                            id="receber-forma"
                                            placeholder="Procurar"
                                            v-model="condicaoSelecionada"
                                            :options="condicoes"
                                            option-key="value"
                                            option-label="text"
                                            :get-option-description="label"
                                            :get-option-value="value"
                                        ></vue-single-select>

                                        <b-input-group-append>
                                            <b-button style="height: 38px" @click="gerarParcelas()" variant="info" v-b-tooltip.hover.bottomleft="'Gerar parcelas'"><i class="fa fa-refresh"></i></b-button>
                                        </b-input-group-append>
                                    </b-input-group>                                    
                                </b-form-group>
                            </b-col>
                        </b-row> 

                        <b-row style="padding: 0 15px 0 15px">
                            <b-collapse id="collapse-1" class="mt-2" v-model="viewEdit">
                                <b-card :header="`${modoParcela} parcela #${parcela.num_parc}`" 
                                    :border-variant="variacaoParcela"
                                    :header-border-variant="variacaoParcela"> 
                                    
                                    <b-row>
                                        <b-col md="3" sm="12">
                                            <b-form-group label="Vencimento" label-for="vencimento-parcela">
                                                <b-form-input id="vencimento-parcela" type="date"
                                                    :formatter="upperCase"
                                                    v-model="parcela.vencimento" required
                                                    :readonly="mode === 'remove'"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <b-form-group label="Valor" label-for="valor-parcela">
                                                <money id="valor-parcela" v-model="parcela.valor" v-bind="money" required class="form-control"/>                                        
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="3" sm="12">
                                            <b-form-group label="Taxa" label-for="taxa-parcela">
                                                <money id="axa-parcela" v-model="parcela.taxa" v-bind="money" required class="form-control"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="3" sm="12" class=" parc-edit-remove">
                                            <b-button  variant="success" v-if="modeParcelas === 'save'"
                                                @click="saveParcela"><i class="fa fa-check"></i></b-button>
                                            <b-button variant="danger" v-if="modeParcelas === 'remove'"
                                                @click="removeParcela"><i class="fa fa-trash-o"></i></b-button>
                                            <b-button class="ml-2" @click="resetParcela"><i class="fa fa-ban"></i></b-button>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-collapse>

                            <b-table hover small striped :items="contaPagar.parcelas" :fields="fieldsParc" borderless fixed>                          
                                <template v-slot:cell(actions)="data">
                                    <b-button size="sm" :disabled="botaoInativo" v-b-toggle.collapse-1 variant="warning" @click="loadParcela(data.item)" class="mr-2">
                                        <i class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-button size="sm" :disabled="botaoInativo" v-b-toggle.collapse-1 variant="danger" @click="loadParcela(data.item, 'remove')">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                                <template #cell(valor)="data">                            
                                    <money type="text" v-model="data.item.valor" v-bind="money" readonly class="moeda-tabela" />
                                </template>
                                <template #cell(saldo)="data">                            
                                    <money type="text" v-model="data.item.saldo" v-bind="money" readonly class="moeda-tabela" />
                                </template>
                                <template #cell(taxa)="data">                            
                                    <money type="text" v-model="data.item.taxa" v-bind="money" readonly class="moeda-tabela" />
                                </template>
                            </b-table>
                        </b-row>
                        
                    </b-tab>

                    <b-tab title="Rateio">
                        <b-row>
                            <b-col md="6" sm="12" v-if="!gerarFatura">
                                <b-form-group label="Consultoria" label-for="conta-pagar-consultoria">
                                    <b-form-select id="conta-pagar-consultoria" v-model="consultoria.id_consultoria" :options="consultorias"/>
                                </b-form-group>
                            </b-col> 
                            <b-col md="2" sm="12" v-if="!gerarFatura">
                                <b-button size="md" style="margin: 31px 0 0 -20px" variant="success" v-b-tooltip.hover.bottomleft="'Adicionar'" @click="addConsultoria">
                                        <i class="fa fa-arrow-down"></i>
                                </b-button>
                            </b-col> 
                        </b-row>
                        <b-row>
                            <b-table class="mt-2" hover striped :items="contaPagar.consultorias" :fields="fieldsConsultorias" small>  
                                <template v-slot:cell(actions)="data">
                                    <b-button variant="danger" @click="removeConsultoria(data.item)">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-row>
                    </b-tab>
                </b-tabs>  
                <br>

                <hr v-if="!gerarFatura">
                <b-row v-if="!gerarFatura">
                    <b-col md="10" sm="10">
                        
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end" v-if="contaPagar.id_conta_pagar">                        

                        <i id="popover-target-conta-pagar"  class="fa fa-question-circle info-alteracoes"></i>

                        <b-popover target="popover-target-conta-pagar" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{contaPagar.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{contaPagar.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{contaPagar.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{contaPagar.data_alt}}
                        </b-popover>                        
                    </b-col>
                </b-row>
            </b-form>
        </b-card> 

        <div v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.topleft="'Cancelar'"
                    @click="reset">
                        <i class="fa fa-times button-icons-acoes"></i>
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'" v-b-tooltip.hover.topleft="'Excluir'"
                    @click="remove">
                        <i class="fa fa-trash button-icons-acoes"></i>
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'" v-b-tooltip.hover.topleft="'Salvar'"
                    @click="save">
                        <i class="fa fa-check button-icons-acoes"></i>
                </b-button>  
            </div>
        </div> 

               

        <b-modal size="xl" id="modal-rel-faturas" title="Relatório de Contas a Pagar" v-model="modalRelFaturas">

            <b-form @submit.prevent="gerarRelatorio">
                <b-row>
                    <b-col md="12" sm="12">
                        <b-input-group>
                            <b-form-select id="rel-tipo" placeholder="Selecione o Tipo" v-model="filters.tipo" :options="tipoOptions" style="width: 10px"/>
                            <b-form-select id="rel-plano-ct" placeholder="Plano de Contas" v-model="filters.plano_contas" :options="planoContas" />
                            <b-form-select id="rel-filial" placeholder="Filial" v-model="filters.filial" :options="filiais" />
                            <b-form-datepicker
                                id="dtp-inicio"
                                placeholder="Início"
                                v-model="filters.data_inicio"
                                v-bind="labels[locale] || {}"
                                locale="pt-BR"
                                :hide-header="true"
                                class="mb-2"
                                required
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            ></b-form-datepicker>
                            <b-form-datepicker
                                id="dtp-fim"
                                placeholder="Fim"
                                v-model="filters.data_fim"
                                v-bind="labels[locale] || {}"
                                locale="pt-BR"
                                :hide-header="true"
                                class="mb-2"
                                required
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            ></b-form-datepicker>
                            <b-input-group-append>
                                <b-button style="height: 38px" variant="info" @click="gerarRelatorio()">
                                    <i class="fa fa-file-text-o button-icons"></i> Gerar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>                   

                    <b-col md="12" sm="12" class="d-flex justify-content-end"  v-if="relResults.length > 0"> 

                        <div>
                            <b-input-group>
                                <b-button 
                                    class="botoes-de-impressao-download"  
                                    variant="info" 
                                    @click="imprimirPdf('render-pdf-rel')" 
                                    v-b-tooltip.hover.bottomleft="'Imprimir'"
                                > 
                                    <i class="fa fa-print button-icons"></i>
                                </b-button> 

                                <b-button  
                                    class="botoes-de-impressao-download" 
                                    variant="danger" 
                                    @click="salvarPdf('render-pdf-rel')" 
                                    v-b-tooltip.hover.bottomleft="'Baixar PDF'"
                                >
                                    <i class="fa fa-file-pdf-o button-icons"></i>
                                </b-button>                                 

                                <b-button 
                                    class="botoes-de-impressao-download"  
                                    variant="success" 
                                    @click="salvarExcel('render-pdf-rel')" 
                                    v-b-tooltip.hover.bottomleft="'Exportar para Excel'"
                                > 
                                    <i class="fa fa-table button-icons"></i>
                                </b-button> 

                            </b-input-group>
                        </div>                      

                    </b-col>

                    
                </b-row>
            </b-form>  
            
            <!-- <div id="render-pdf-rel" v-html="impresso" class="ql-editor">  </div> -->
            <b-card class="mt-3">
                <b-row v-if="relResults.length > 0">
                    <b-col md="12" sm="12" class="d-flex justify-content-center"> 
                        <div  id="render-pdf-rel" style="width: 775px; background-color: white">  
                            <h3>{{tituloRel}}</h3>
                            <h4>{{nomeFilial}}</h4>
                            <h5>Período: {{dtIni}} até {{dtFin}}</h5>
                            <hr>

                            <b-table 
                                borderless
                                small                                        
                                responsive
                                sort-icon-left
                                head-variant="light"
                                :items="tableData" 
                                :fields="fieldsFaturas"
                            >
                            </b-table>

                        </div>
                    </b-col>
                </b-row>                
            </b-card>
            
            
            
            <template #modal-footer>
                <div class="w-100 h-50">                    
                    
                </div>
            </template>

        </b-modal>

        <b-modal size="xl" id="modal-rel-faturas" title="Detalhes da Fatura" v-model="modalDetalhes">
            <template #modal-title>
                <h4><strong>Docto nº: {{contaPagar.num_docto || contaPagar.id_conta_pagar}} | {{contaPagar.razao_social}}</strong></h4>
            </template>

            <b-row>
                <b-col md="12" sm="12"> 
                    <b-row> 
                        <b-col md="4" sm="12">  
                            <b-card no-body style="padding: 10px">
                                <small>Competência</small>                                
                                <span style="font-size: 1.3rem; margin-top: 5px"><strong>{{contaPagar.data_competencia}}</strong></span>
                            </b-card>
                        </b-col>   
                        <b-col md="4" sm="12">  
                            <b-card no-body style="padding: 10px">
                                <small>Data Docto</small>                                  
                                <span style="font-size: 1.3rem; margin-top: 5px"><strong>{{contaPagar.data_documento}}</strong></span>
                            </b-card>
                        </b-col>   
                        <b-col md="4" sm="12">  
                            <b-card no-body style="padding: 10px">
                                <small>Valor</small>                                  
                                <span style="font-size: 1.3rem; margin-top: 5px"><strong>{{contaPagar.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</strong></span>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row> 
                        <b-col md="12" sm="12">  
                            <b-card no-body style="padding: 10px">
                                <small>Descrição</small>   
                                <p style="font-size: 1.1rem; margin-top: 5px">{{contaPagar.descricao}}</p>
                            </b-card>
                        </b-col>
                    </b-row>
                    <br>

                    <b-tabs content-class="mt-3">
                        <b-tab title="Negociação" active> 
                            <b-row> 
                                <b-col md="4" sm="12">  
                                    <b-card no-body style="padding: 10px">
                                        <small>Plano de contas</small>                                  
                                        <span style="font-size: 1.1rem; margin-top: 5px">{{contaPagar.id_plano_conta}} | {{contaPagar.desc_plano_conta}}</span>
                                    </b-card>
                                </b-col>   
                                <b-col md="4" sm="12">  
                                    <b-card no-body style="padding: 10px">
                                        <small>Forma</small>                                  
                                        <span style="font-size: 1.1rem; margin-top: 5px">{{contaPagar.id_forma_pagar_receber}} | {{contaPagar.desc_forma}}</span>
                                    </b-card>
                                </b-col>   
                                <b-col md="4" sm="12">  
                                    <b-card no-body style="padding: 10px">
                                        <small>Condição</small>                                  
                                        <span style="font-size: 1.1rem; margin-top: 5px">{{contaPagar.id_condicao_pagar_receber}} | {{contaPagar.desc_condicao}}</span>
                                    </b-card>
                                </b-col>
                            </b-row> 

                            <b-row style="padding: 0 15px 0 15px">                                
                                <b-table hover small striped :items="parcelas" :fields="fieldsParcDetalhes" borderless fixed>                                     
                                </b-table>
                            </b-row>    
                        </b-tab>

                        <b-tab title="Histórico Financeiro"> 
                            <b-row style="padding: 0 15px 0 15px">  
                                                             
                                <b-table hover small striped :items="contaPagarHistorico" :fields="fieldsHistorico" borderless fixed>   
                                    <template v-slot:cell(actions)="row">
                                        <div class="d-flex justify-content-end"> 
                                            <b-button size="sm" @click="detalhar(row)" class="mr-2">
                                                <i v-if="!row.item._showDetails" class="fa fa-chevron-down"></i>
                                                <i v-else class="fa fa-chevron-up"></i>
                                            </b-button> 
                                        </div>
                                    </template> 

                                    <template #row-details="row">                                        
                                        <b-card no-body class="p-2">
                                            <b-row>
                                                <b-col md="6" sm="12"> 
                                                    <b-card border-variant="danger" no-body style="padding: 10px">
                                                        <small>Pago</small>  
                                                        <span style="font-size: 1.2rem;">{{row.item.totais.valor_pago}}</span>
                                                    </b-card>
                                                </b-col>

                                                <b-col md="6" sm="12"> 
                                                    <b-card border-variant="success" no-body style="padding: 10px">
                                                        <small >Recebido</small>  
                                                        <span style="font-size: 1.2rem;">{{row.item.totais.valor_recebido}}</span>
                                                    </b-card>
                                                </b-col>
                                            </b-row>

                                            <b-row>
                                                <b-col md="12" sm="12"> 
                                                    <b-card no-body style="padding: 10px">
                                                        <small>Histórico</small>  
                                                        {{row.item.totais.historico}}
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                            <br>

                                            <b-table hover small striped :items="row.item.numerarios" :fields="fieldsHistoricoDet" borderless responsive>
                                                <template #cell(tipo)="data">
                                                    <i v-if="data.item.tipo === 'CT'" class="fa fa-credit-card-alt ml-3" v-b-tooltip.hover.right="'Cartão'"></i>
                                                    <i v-if="data.item.tipo === 'CH'" class="fa fa-list-alt ml-3" v-b-tooltip.hover.right="'Cheque'"></i>
                                                    <i v-if="data.item.tipo === 'CB'" class="fa fa-university ml-3" v-b-tooltip.hover.right="'Banco'"></i>
                                                    <i v-if="data.item.tipo === 'DN'" class="fa fa-money ml-3" v-b-tooltip.hover.right="'Dinheiro'"></i>
                                                </template>
                                            </b-table>
                                        </b-card>
                                    </template>                                
                                </b-table>
                            </b-row> 
                            <br>
                        </b-tab>
                    </b-tabs> 
                </b-col> 

            </b-row>
            <br>
            <template #modal-footer>
                <div class="w-100 h-50">                    
                    
                </div>
            </template>

        </b-modal>
        
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import {VMoney} from 'v-money'
import axios from 'axios'
import { showSuccess } from '../../../global'
const moment = require('moment')
import html2pdf from "html2pdf.js"
import 'moment/locale/pt-br';
moment.locale('pt-br');
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'; // Não esqueça de importar o CSS
import 'vue2-datepicker/locale/pt-br';

import VueSingleSelect from "vue-single-select";

export default {
    name: 'CadastroFaturaPagar',
    components: { Datepicker, VueSingleSelect },
    props: ['fatura', 'gerarFatura', 'id_lanc'],
    emits: ['enviaParcelas', 'salvar-despesa'],
    data: function() {
        return {
            intervaloVenc: [],
            intervaloComp: [],
            consulta: {
                parcial: '',
                dataIniComp: null,
                dataFimComp: null,
                dataIniVenc: null,
                dataFimVenc: null,
                filial: 0,
                idPlanoConta: 0,
                status: ['A','P'],   
                opcoes: [
                    { value: 'A', text: 'Abertas' },
                    { value: 'P', text: 'Parciais'},
                    { value: 'Q', text: 'Quitadas' },
                ]  
            },

            locale:"pt-BR",
            labels: {
                "pt-BR": {
                    labelPrevDecade: "Década Anterior",
                    labelPrevYear: "Ano Anterior",
                    labelPrevMonth: "Mês Anterior",
                    labelCurrentMonth: "Mês Atual",
                    labelNextMonth: "Próximo Mês",
                    labelNextYear: "Próximo Ano",
                    labelNextDecade: "Próxima Década",
                    labelToday: "Hoje",
                    labelSelected: "Data Selecionada",
                    labelNoDateSelected: "Nenhuma Data Selecionada",
                    labelCalendar: "Calendário",
                    labelNav: "Navegação do Calendário",
                    labelHelp: "Navegue pelo calendário usando as teclas de seta"
                }
            },
            filters: {
                data_inicio: moment().add(-1, 'M').format('YYYY-MM-01'),
                data_fim: moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
                plano_contas: 0,
                filial: 0,
                tipo: 'A'
            },
            dtIni: '',
            dtFin: '',
            nomeFilial: '',
            tipoOptions: [
                { text: 'Sintético', value: 'S' },
                { text: 'Analítico', value: 'A' }
            ],
            relResults: [],
            tituloRel: '',
            nomeArquivo: '',
            fieldsFaturas: [],
            modalRelFaturas: false,
            modalDetalhes: false,
            contaPagarHistorico: [],

            filiais: [],
            parcelas: [],

            viewEdit: false,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            index: -1,
            mode: 'new',
            botaoInativo: false,
            modeParcelas: 'save',
            variacaoParcela: 'warning',
            modoParcela:'Editar',
            contaPagar: {
                id_lancamento: this.id_lanc || 0,
                id_pessoa: '',
                descricao: '',
                valor: 0.00,
                data_competencia: moment().format('YYYY-MM-DD'),
                data_documento: moment().format('YYYY-MM-DD'),
                status: 'A',
                consultorias: [],
                parcelas: []
            },
            moeda: new Intl.NumberFormat('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2
            }),
            idContPag: 0,
            contasPagar: [],

            pessoaSelecionada: '',
            vendedorSelecionado: '',
            planoSelecionado: '',
            formaSelecionada: '', 
            condicaoSelecionada: '', 

            pessoas: [],
            formas: [],
            condicoes: [],
            planoContas: [],
            consultorias: [],

            consultoria: {
                seq: 0,
                id_consultoria: 0,
                nome: ''
            },
            
            parcela: {},
            carregandoCond: false,
            carregandoForm: false,
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            status: 'A',
            idPlanoConta: 0,
            teste: 0,
            show: true,
            mascaras: {
                masked: true,
                cpf: '###.###.###-##',
                cnpj: '##.###.###/####-##',
                telefone: '(##) ####-####',
                celular: '(##) # ####-####',
                cep: '#####-###',
                cfop: '#.###',
                nf: '##  ####  ##############  ##  ###  #########  #  ########  #',
                docto: '####################',
                serie: 'NNN',
                mod: 'NNN',
                prot: '#############################################'
            },
            fieldsParcDetalhes: [
                {key: 'num_parc', label: 'Nº Parc', sortable: true},
                {key: 'vencimento', label: 'Venc.', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'valor', label: 'Valor', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'saldo', label: 'Saldo', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'taxa', label: 'Taxa', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
            ],
            fieldsHistorico: [
                {key: 'data_recebimento', label: 'Recebimento', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'num_parc', label: 'Nº Parc', sortable: true},                
                {key: 'valor_saldo', label: 'Saldo', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'desconto', label: 'Desconto', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'acrescimo', label: 'Acréscimo', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'valor_liquido', label: 'Recebido', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'status', label: 'Status', 
                    formatter: value => { 
                        switch (value) {
                            case 'A': return "Aberta" 
                            case 'P': return "Parcial" 
                            case 'Q': return "Quitada"
                            case 'R': return "Renegociada"                        
                            case 'C': return "Cancelada"
                        }
                    }
                },
                {key: 'actions', label: '', sortable: false}                
            ],

            fieldsHistoricoDet: [
                {key: 'tipo', label: '', sortable: false},
                {key: 'descricao', label: 'Descrição', sortable: false},               
                {key: 'valor', label: 'Valor', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }               
            ],

            fields: [
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'num_docto', label: 'Docto', sortable: true},
                {key: 'num_parc', label: 'Parc', sortable: true},
                {key: 'data_competencia', label: 'Competência', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'vencimento', label: 'Vencimento', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'quitacao', label: 'Quitação', sortable: true, 
                    formatter: value => { 
                        return value ? moment(value).format('DD/MM/YYYY') : null
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'saldo', label: 'Saldo', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'status', label: 'Status', 
                    formatter: value => { 
                        switch (value) {
                            case 'A': return "Aberta" 
                            case 'P': return "Parcial" 
                            case 'Q': return "Quitada"
                            case 'R': return "Renegociada"                        
                            case 'C': return "Cancelada"
                        }
                    }
                },
                {key: 'actions', label: ''}
            ],
            fieldsParc: [
                //{key: 'id_conta_pagar', label: 'Index', sortable: true},
                {key: 'num_parc', label: 'Nº Parc', sortable: true},
                {key: 'vencimento', label: 'Venc.', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true},
                {key: 'saldo', label: 'Saldo', sortable: true},
                {key: 'taxa', label: 'Taxa', sortable: true},                
                {key: 'actions', label: 'Ações'}
            ],
            fieldsConsultorias: [                 
                {key: 'nome', label: 'Nome', sortable: true},              
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        if (value) return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                    }
                },             
                {key: 'actions', label: ''}
            ],
            optionsConsultaFR: [
                { value: 'A', text: 'Abertas' },
                { value: 'P', text: 'Parciais'},
                { value: 'Q', text: 'Quitadas' },
                { value: 'R', text: 'Renegociadas' },
                { value: 'C', text: 'Canceladas' },
                { value: 'T', text: 'Todas' }
            ],  
        }
    },
    directives: {
        money: VMoney
    },
    computed: {
        tableData() {

            let ret = []

            if (this.filters.tipo === 'A') {
                const totalValor = this.relResults.reduce((sum, item) => sum + (item.valor || 0), 0);
                const totalSaldo = this.relResults.reduce((sum, item) => sum + (item.saldo || 0), 0);
                // Adicionar linha de total ao final
                ret = [
                    ...this.relResults,
                    {
                        id_conta_pagar: '',
                        id_forma_pagar_receber: '',
                        id_condicao_pagar_receber: '',
                        id_pessoa: '',
                        razao_social: 'TOTAL',
                        id_filial: '',
                        id_empresa: '',
                        id_plano_conta: '',
                        data_competencia: '',
                        data_documento: '',
                        valor: totalValor,
                        saldo: totalSaldo,
                        _rowVariant: 'danger'
                    }
                ];
            }

            if (this.filters.tipo === 'S') {
                const totalValor = this.relResults.reduce((sum, item) => sum + (item.total_valor || 0), 0);
                // Adicionar linha de total ao final
                ret = [
                    ...this.relResults,
                    {
                        id_plano_conta: '',
                        descricao: 'Total',
                        total_valor: totalValor,
                        _rowVariant: 'danger'
                    }
                ];
            }

            return ret
        }
    },
    methods: {
        detalhar(row) {
            row.toggleDetails()            
            row.item._showDetails ? row.item._rowVariant = 'primary' : delete row.item._rowVariant
        },
        loadDetalhes(contaPagar) {            

            const url = `${baseApiUrl}/contas_pagar/${contaPagar.id_conta_pagar}`    

            axios.get(url).then(async res => {

                this.contaPagar =  res.data 
                this.contaPagar.data_competencia = moment(this.contaPagar.data_competencia).format('DD/MM/YYYY')  
                this.contaPagar.data_documento = moment(this.contaPagar.data_documento).format('DD/MM/YYYY')  

                this.parcelas = [ ...this.contaPagar.parcelas ]

                await this.loadHistoricoFinanceiro(this.contaPagar.id_conta_pagar, 'P')
                        
                this.modalDetalhes = true             
            }) 
            .catch(err => {
                showError(err)
            })           
            
        },

        async loadHistoricoFinanceiro(id, tipo) { 
            axios.get(`${baseApiUrl}/consulta/historico_financeiro`, {
                params: { 
                    idFatura: id,
                    tipo: tipo
                }
            })
            .then(res => {

                this.contaPagarHistorico =  [ ...res.data ]          
            }) 
            .catch(err => {
                showError(err)
            })           
            
        },

        label(option){
            return option.text
        },
        value(option){
            return option.value
        },
        novaFatura() {
            this.pessoaSelecionada = ''
            this.formaSelecionada = ''
            this.planoSelecionado = '' 
            this.condicaoSelecionada = '' 

            this.loadPlanoContas(false),
            this.loadFormas(),
            this.loadCondicoes(),
            this.loadConsultorias()
            this.loadPessoas() 

            this.show = false
            this.mode = 'save'
        },
        desejaExcluirFatura() {

        },
        opcoesFaturas() {

        },
        relatorios() {

        },
        addConsultoria() {
            if (this.consultoria.id_consultoria < 1) {
                showError('Selecione uma consultoria antes de gravar.')
                return
            }

            const m = this.consultorias.find(obj => {
                return obj.id_consultoria === this.consultoria.id_consultoria;
            });
            this.consultoria.nome = `${m.nome} (${m.razao_social})`

            this.contaPagar.consultorias.push({...this.consultoria})             

            this.consultoria.nome = ''  
            this.consultoria.id_consultoria = 0  
            this.consultoria.seq = 0 
            
            this.enumeraConsultoria()
        },

        calculaConsultoria() {
            if (this.contaPagar.consultorias.length > 0) {
                const vConta = parseFloat(this.contaPagar.valor).toFixed(2)  
                const qParc = this.contaPagar.consultorias.length
                
                //Parcelas
                const vParc = parseFloat(vConta / qParc).toFixed(2) 
                const restop = parseFloat((vConta) - parseFloat((vParc*qParc)).toFixed(2)).toFixed(2)  
                const ultParc = (Number(vParc) + Number(restop))
    
                for (let i = 0; i < qParc; i++){ 
                    this.contaPagar.consultorias[i].valor = (i+1 === qParc) ? parseFloat(ultParc).toFixed(2) : parseFloat(vParc).toFixed(2)               
                }
            }
        },

        enumeraConsultoria(){ 
            this.contaPagar.consultorias.forEach((_, i) =>{                
                this.contaPagar.consultorias[i].seq = i+1
            })

            this.calculaConsultoria()
        },

        removeConsultoria(i) {            
            this.contaPagar.consultorias.splice(Number(i.seq-1), 1)
            this.enumeraConsultoria()
        },
        limiteCaracteres(value, q) {
             if(value) {return value.length <= q ? true : false } else {return true}
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadContasPagar()            
        },

        loadContasPagar() { 
            const url = `${baseApiUrl}/consulta/cadastros/?tipo=contapagar&page=${this.page}` 
            
            axios.get(url, {
                params: {
                    consulta: this.consulta
                }
            })
            .then(res => {
                this.contasPagar = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            }) 
            .catch(err => {
                showError(err)
            }) 
        }, 

        loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            axios.get(url).then(res => {
                this.pessoas = res.data.map(pessoa => {
                    return { ...pessoa, value: pessoa.idpessoa, text: `| ${pessoa.razao_social}` }
                })
            })
        },

        async loadConsultorias() {
            await axios.get(`${baseApiUrl}/consultorias`)
            .then(res => {
                this.consultorias = res.data.map(cons => {
                    return { ...cons,  value: cons.id_consultoria, text: `${cons.nome} (${cons.razao_social})`}
                })
            })  
        },

        async loadFormas() {
            const url = `${baseApiUrl}/formas_pagar_receber`
            await axios.get(url).then(res => {
                this.formas = res.data.map(forma => {
                    return { ...forma, value: forma.id_forma_pagar_receber, text: `${forma.id_forma_pagar_receber} | ${forma.descricao}` }
                })
            })
        },          
        async loadCondicoes() {
            const url = `${baseApiUrl}/condicoes_pagar_receber` 
            await axios.get(url).then(res => {
                this.condicoes = res.data.map(condicao => {
                    return { ...condicao, value: condicao.id_condicao_pagar_receber, text: `${condicao.id_condicao_pagar_receber} | ${condicao.descricao}` }
                })
            })
        },                
        loadPlanoContas(exibeTodas) {
            const url = `${baseApiUrl}/plano_contas/?tipo=D`
            axios.get(url).then(res => {
                this.planoContas = res.data.map(planoConta => {
                    return { ...planoConta, value: planoConta.id_plano_conta, text: `${planoConta.id_plano_conta} | ${planoConta.descricao}` }
                })
                
                if(exibeTodas) this.planoContas.unshift({value: 0, text: 'Todas as Despesas' })
            })
        },
        gerarParcelas(){
            if (this.contaPagar.id_forma_pagar_receber > 0 && this.contaPagar.id_condicao_pagar_receber > 0 ) {
                const forma = this.formas.filter(obj =>{
                    if ('id_forma_pagar_receber' in obj  && obj.id_forma_pagar_receber === this.contaPagar.id_forma_pagar_receber) {
                            return obj
                        } 
                })
                const cond = this.condicoes.filter(obj =>{
                    if ('id_condicao_pagar_receber' in obj  && obj.id_condicao_pagar_receber === this.contaPagar.id_condicao_pagar_receber) {
                            return obj
                        } 
                })

                this.contaPagar.parcelas = []

                if (cond.length > 0) {

                    const vConta = parseFloat(this.contaPagar.valor).toFixed(2)  
                    const qParc = cond.length > 0 ? cond[0].qtd_parcelas : 0 
                    const intv = cond.length > 0 ? cond[0].intervalo : 0 
                    
                    //Parcelas
                    const entr = cond[0].possui_entrada
                    const vParc = parseFloat(vConta / qParc).toFixed(2) 
                    const restop = parseFloat((vConta) - parseFloat((vParc*qParc)).toFixed(2) ).toFixed(2)  
                    const ultParc = (Number(vParc) + Number(restop))
                    
                    //Taxas
                    const coms = forma[0].comissao
                    const taxa = forma[0].taxa
                    const vTaxa = parseFloat((vConta * coms /100) + taxa).toFixed(2) 
                    const vTaxap = parseFloat(vTaxa / qParc).toFixed(2) 
                    const restot = parseFloat((vTaxa) - parseFloat((vTaxap*qParc)).toFixed(2) ).toFixed(2) 
                    const ultTaxa = (Number(vTaxap) + Number(restot))
        
                    for (let i = 0; i < qParc; i++){   
        
                        const dias =   entr === 'S' ?  (i + 0) * intv : (i + 1) * intv
        
                        this.contaPagar.parcelas.push({
                            "num_parc": i + 1 , 
                            "valor": (i+1 === qParc) ? parseFloat(ultParc).toFixed(2) : parseFloat(vParc).toFixed(2),
                            "saldo": (i+1 === qParc) ? parseFloat(ultParc).toFixed(2) : parseFloat(vParc).toFixed(2),
                            "taxa": (i+1 === qParc) ? parseFloat(ultTaxa).toFixed(2) : parseFloat(vTaxap).toFixed(2),
                            "vencimento": moment(this.contaPagar.data_competencia).add(dias, 'days').format('YYYY-MM-DD')               
                        })                
                    }
        
                    if(this.fatura) {
                        const p = [ ...this.contaPagar.parcelas ]
                        this.$emit('enviaParcelas', p )
                    }
                }
            }
        },
        reset() {
            this.mode = 'new'

            this.contaPagar = {
                id_pessoa: '',
                descricao: '',
                valor: 0.00,
                data_competencia: moment().format('YYYY-MM-DD'),
                data_documento: moment().format('YYYY-MM-DD'),
                status: 'A',
                consultorias: [],
                parcelas: []
            }

            this.pessoaSelecionada = ''
            this.formaSelecionada = ''
            this.planoSelecionado = '' 
            this.condicaoSelecionada = '' 

            this.loadContasPagar()
            this.resetParcela()
            this.loadPlanoContas(true);
            this.idContPag = 0

            this.show = true
        },        
        resetParcela(){
            this.viewEdit = false
            this.modoParcela = 'Editar'
            this.variacaoParcela = 'warning'
            this.modeParcelas = 'save'
            this.parcela = {}
            this.index = -1
            this.botaoInativo = false
            this.enumeraParcelas()
        },

        enumeraParcelas() {
            this.contaPagar.parcelas.forEach((_, i) =>{                
                this.contaPagar.parcelas[i].num_parc = i + 1 
            })
        },

        validaParcelas() {

            if(this.contaPagar.id_forma_pagar_receber < 0) {
                showError('Selecione uma forma de pagamento.')
                return false
            }
            if(this.contaPagar.id_condicaoa_pagar_receber < 0) {
                showError('Selecione uma condição de recebimento.')
                return false
            }

            const forma = this.formas.filter(obj =>{
                if ('id_forma_pagar_receber' in obj  && obj.id_forma_pagar_receber === this.contaPagar.id_forma_pagar_receber) {
                    return obj
                } 
            })            

            const vConta = parseFloat(this.contaPagar.valor).toFixed(2)  
            const taxa = forma[0].taxa
            const coms = forma[0].comissao

            const vTaxa = parseFloat((vConta * coms /100) + taxa).toFixed(2) 
            let somaParc = 0
            let somaTaxa = 0

            this.contaPagar.parcelas.forEach((el, i)=>{
                somaParc = somaParc + this.contaPagar.parcelas[i].valor
                somaTaxa = somaTaxa + this.contaPagar.parcelas[i].taxa
            })

            if (Number(parseFloat(somaParc).toFixed(2)  ) !== Number(parseFloat(vConta).toFixed(2)  )) { 
                showError('A soma das parcelas é diferente do valor da conta.')
                return false
            }
            if (Number(parseFloat(vTaxa).toFixed(2)  ) !== Number(parseFloat(somaTaxa).toFixed(2)  )) {
                showError('A soma das taxas das parcelas não bate com o valor calculado.') 
                return false
            }

            return true            
            
        },

        async gerarRelatorio() {
            try { 
                // axios.get(`${baseApiUrl}/rel_contas_pagar`, { filters: this.filters })

                // const fltrs = this.filters
                
                axios.get(`${baseApiUrl}/rel_contas_pagar`, {
                    params: { ...this.filters }
                })
                .then(async res => {
                    switch (this.filters.tipo) {
                        case 'A':
                            this.nomeArquivo = 'rel-contas-a-pagar(analitico)'
                            this.tituloRel = 'Relatório de Contas a Pagar (Analítico)' 
                            this.fieldsFaturas = [
                                {key: 'razao_social', label: 'Nome', sortable: true},
                                {key: 'num_docto', label: 'Docto', sortable: true},
                                {key: 'valor', label: 'Valor', sortable: false,
                                    formatter: value => {
                                        return this.filters.tipo ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0
                                    }
                                },
                                {key: 'saldo', label: 'Saldo', sortable: false,
                                    formatter: value => {
                                        return this.filters.tipo ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0
                                    }
                                },
                                {key: 'status', label: 'Status', 
                                    formatter: value => { 
                                        switch (value) {
                                            case 'A': return "Aberta" 
                                            case 'P': return "Parcial" 
                                            case 'Q': return "Quitada"
                                            case 'R': return "Renegociada"                        
                                            case 'C': return "Cancelada"
                                        }
                                    }
                                }
                            ]                   
                            break;
                        case 'S':
                            this.nomeArquivo = 'rel-contas-a-pagar(sintetico)'
                            this.tituloRel = 'Relatório de Contas a Pagar (Sintético)'  
                            this.fieldsFaturas = [
                                {key: 'id_plano_conta', label: 'Cód', sortable: true},
                                {key: 'descricao', label: 'Plano de Contas', sortable: true},
                                {key: 'total_valor', label: 'Valor Total', sortable: false,
                                    formatter: value => {
                                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                                    }
                                },
                            ]                  
                            break;
                    
                        default:
                            this.nomeArquivo = 'rel-contas-a-pagar'
                            this.tituloRel = 'Relatório de Contas a Pagar' 
                        break;
                    }

                    this.filiais.forEach( obj => {
                        if (obj.value === this.filters.filial) {
                            this.nomeFilial = obj.text
                        } 
                    }) 

                    this.dtIni = moment(this.filters.data_inicio).format('DD/MM/yyyy')
                    this.dtFin = moment(this.filters.data_fim).format('DD/MM/yyyy')

                    this.relResults = res.data

                }) 
                .catch(err => {
                    showError(err)
                })

            } catch (err) {
                showError(err)
            }            
        },

        loadRelFatura() { 
            this.modalRelFaturas = true  
            this.loadPlanoContas(true)
            this.loadFiliais()        
        }, 

        salvarPdf(id){
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.25,0.25,0.25,0.25],
                filename: `${this.nomeArquivo}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },

        imprimirPdf(id) {
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin: [0.25, 0.25, 0.25, 0.25],
                filename: `${this.nomeArquivo}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf')
            .then(function (pdf) {
                var totalPages = pdf.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(8);
                    pdf.setTextColor(75);
                    pdf.text('Página ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() / 2) - 0.4, (pdf.internal.pageSize.getHeight() - 0.2));
                }

                // Convertendo o PDF para um blob
                var pdfBlob = pdf.output('blob');

                // Criando uma URL de objeto para o blob
                var url = URL.createObjectURL(pdfBlob);

                // Abrindo o PDF em uma nova aba
                window.open(url, '_blank');
            })
            .catch(function (err) {
                showError(err)
            });
        },

        salvarExcel() {
            // Crie uma nova planilha
            const ws = XLSX.utils.json_to_sheet(this.relResults);

            // Crie um novo livro de trabalho e adicione a planilha
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Dados');

            // Converta o livro de trabalho para um arquivo Excel
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Salve o arquivo
            saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${this.nomeArquivo}.xlsx`);
        },

        async loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            await axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
                this.filiais.unshift({value: 0, text: 'Todas as Filiais' })
            })
            .catch(err => {
                showError(err)
            }) 
        },

        save() {
            if (this.validaParcelas()) {

                this.calculaConsultoria()

                const method = this.contaPagar.id_conta_pagar ? 'put' : 'post'
                const idcontapagar = this.contaPagar.id_conta_pagar ? `/${this.contaPagar.id_conta_pagar}` : ''
    
                axios[method](`${baseApiUrl}/contas_pagar${idcontapagar}`, this.contaPagar)
                .then(() => {
                    showSuccess('Fatura a pagar salva com sucesso')
                    this.reset()
                })
                .catch(err => {
                    showError(err)
                })   
            }            

        },
        saveParcela() { 
            this.parcela.saldo = this.parcela.valor

            if (this.index < 0) {
                this.contaPagar.parcelas.push({...this.parcela})
            } else {
                this.index = this.parcela.num_parc - 1
                this.contaPagar.parcelas.splice(this.index, 1, {...this.parcela})
            }             
            
            this.resetParcela()
        },
        remove() {
            const idcontapagar = this.contaPagar.id_conta_pagar
            axios.delete(`${baseApiUrl}/contas_pagar/${idcontapagar}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        removeParcela() {
            this.index = this.parcela.num_parc - 1
            this.contaPagar.parcelas.splice(this.index, 1)
            this.resetParcela()
        },        

        loadContaPagar(cp, mode = 'save') {                     

            axios.get(`${baseApiUrl}/contas_pagar/${cp.id_conta_pagar}`)
            .then(async res => {
                this.loadPlanoContas(false),
                await this.loadConsultorias()
                await this.loadFormas(),
                await this.loadCondicoes(),

                this.mode = mode   

                this.carregandoCond = true
                this.carregandoForm = true                

                this.contaPagar = { ...res.data }
                this.contaPagar.data_competencia = moment(this.contaPagar.data_competencia ).format('YYYY-MM-DD')   
                this.contaPagar.data_documento = moment(this.contaPagar.data_documento).format('YYYY-MM-DD') 

                this.pessoaSelecionada = {value: this.contaPagar.id_pessoa, text: `| ${this.contaPagar.razao_social}`}
                this.formaSelecionada = {value: this.contaPagar.id_forma_pagar_receber, text: `${this.contaPagar.id_forma_pagar_receber} | ${this.contaPagar.desc_forma}`}
                this.condicaoSelecionada = {value: this.contaPagar.id_condicao_pagar_receber, text: `${this.contaPagar.id_condicao_pagar_receber} | ${this.contaPagar.desc_condicao}`}
                this.planoSelecionado = {value: this.contaPagar.id_plano_conta, text: `${this.contaPagar.id_plano_conta} | ${this.contaPagar.desc_plano_conta}`}

                this.contaPagar.consultorias = [ ...this.contaPagar.consultorias ]                 
                this.contaPagar.parcelas = [ ...this.contaPagar.parcelas ]    
                this.contaPagar.parcelas.forEach(p=>{
                    p.vencimento = moment(p.vencimento).format('yyyy-MM-DD')
                })               
                
                this.show = false
               
            }) 
            .catch(err => {
                showError(err)
            })
            
        },

        loadParcela(parcela, modeParcelas = 'save') {                       
            this.modeParcelas = modeParcelas
            
            if(this.modeParcelas === 'save') {
                this.modoParcela = 'Editar'
                this.variacaoParcela = 'warning' 
            } else {
                this.modoParcela = 'Excluir'
                this.variacaoParcela = 'danger' 
            }

            this.parcela = { ...parcela }               
            this.index = this.parcela.num_parc - 1
                    
            setTimeout(()=> {this.botaoInativo = true}, 1)
        },
        carregaFatura(f) { 
            this.contaPagar.valor = f.total_liquido
            this.contaPagar.id_forma_pagar_receber = f.id_forma_pagar_receber
            this.contaPagar.id_condicao_pagar_receber = f.id_condicao_pagar_receber 
            
            this.gerarParcelas()  
        }

    },
    mounted () {
        this.loadPlanoContas(true);
        this.loadFiliais();       
    }, 
    watch: {
        intervaloVenc() {
            this.consulta.dataIniVenc = this.intervaloVenc[0] ? moment(this.intervaloVenc[0]).format('YYYY-MM-DD') : null
            this.consulta.dataFimVenc = this.intervaloVenc[1] ? moment(this.intervaloVenc[1]).format('YYYY-MM-DD') : null
        },
        intervaloComp() {
            this.consulta.dataIniComp = this.intervaloComp[0] ? moment(this.intervaloComp[0]).format('YYYY-MM-DD') : null
            this.consulta.dataFimComp = this.intervaloComp[1] ? moment(this.intervaloComp[1]).format('YYYY-MM-DD') : null
        },

        pessoaSelecionada() {
            if (this.pessoaSelecionada) {
                this.contaPagar.id_pessoa = this.pessoaSelecionada.value
            } else {
                this.contaPagar.id_pessoa = ''
            }
        },
        formaSelecionada() {
            if (this.formaSelecionada) {
                this.contaPagar.id_forma_pagar_receber = this.formaSelecionada.value
            } else {
                this.contaPagar.id_forma_pagar_receber = ''
            }
        },        
        condicaoSelecionada() {
            if (this.condicaoSelecionada) {
                this.contaPagar.id_condicao_pagar_receber = this.condicaoSelecionada.value
                this.gerarParcelas() 
            } else {
                this.contaPagar.id_condicao_pagar_receber = ''
                this.contaPagar.parcelas = []
            }
        },        
        planoSelecionado() {
            if (this.planoSelecionado) {
                this.contaPagar.id_plano_conta = this.planoSelecionado.value
            } else {
                this.contaPagar.id_plano_conta = ''
            }
        },

        gerarFatura() {            
            this.carregaFatura(this.fatura)            
        },
        page() {
            this.loadContasPagar()
        },
        'contaPagar.id_condicao_pagar_receber': function() {
            if (!this.carregandoCond) {
                this.gerarParcelas()
            } else {
                this.carregandoCond = false
            }
        },
        'contaPagar.id_forma_pagar_receber': function() {
            if (!this.carregandoForm) {
                this.gerarParcelas()
            } else {
                this.carregandoForm = false
            }
        }

    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;        
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .frase-erro {
        color: crimson;
    }

    .parc-edit-remove {
        padding: 32px 20px 15px 0;
    }

    .moeda-tabela {
        display: flex;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
        background: transparent;
        width: 100%;
        text-align: left;
    }

    .containerRoll {
        height: calc(100vh - 200px); /* Ajuste a altura de acordo com a altura do card fixo */
        overflow-y: auto;
        scrollbar-width: none; /* Para navegadores Firefox */
        -ms-overflow-style: none; /* Para Internet Explorer e Edge */
        padding-bottom: 60px;
    }

    .containerRoll::-webkit-scrollbar {
        display: none; /* Para navegadores WebKit (Chrome, Safari) */
    }

    .sticky-card-fat-pag {
        position: sticky;
        top: 0; 
        background-color: #f9f9f9;
        z-index: 5; /* Para garantir que fique acima de outros elementos */
    }

    .btn-condicao {
        width: calc(100% - 45px);
    }

</style>