<template>
    <!-- <aside class="sidebar" v-show="isMenuVisible"> -->
    <aside class="sidebar"     
        :class="{'closeup': isMenuVisible && user && isMenuCompact}">        
        
        <ul class="nav-links"> 
            <li class="tog-plan">
                <router-link to="#">
                    <a class="toggle" @click="toggleMenu" v-if="!hideToggle" >
                        <i class="fa fa-lg" :class="!this.$store.state.isMenuCompact ? 'fa-angle-double-left' : 'fa-angle-double-right'"></i>
                    </a>
                    <span  @click="chamaPlanos" class="link_name plano">{{ user.plano === 'lite' ? 'Plano Lite' : 'Plano Plus' }}</span>                    
                </router-link>
            </li>

            <li>
                <router-link to="/">
                    <i class='fa fa-home' ></i>
                    <span class="link_name">Dashboard</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/"> <li><a class="link_name" href="#">Dashboard</a></li> </router-link>
                </ul>
            </li>

            <li v-if="ativaMenu(4, user.menus)">
                <router-link to="/funil">
                    <i class='fa fa-filter' ></i>
                    <span class="link_name">Funil de Vendas</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/funil"> <li><a class="link_name" href="#">Funil de Vendas</a></li> </router-link>
                </ul>
            </li>

            <!-- <li>
                <router-link to="/lancamentos">
                    <i class='fa fa-rocket' ></i>
                    <span class="link_name">Lançamentos</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/lancamentos"> <li><a class="link_name" href="#">Lançamentos</a></li> </router-link>
                </ul>
            </li> -->

            <li v-if="ativaMenu(1, user.menus)">
                <router-link to="/projetos">
                    <i class='fa fa-rocket' ></i>
                    <span class="link_name">Projetos</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/projetos"> <li><a class="link_name" href="#">Projetos</a></li> </router-link>
                </ul>
            </li>

            <!-- <li>
                <router-link to="/vendas">
                    <i class='fa fa-shopping-basket' ></i>
                    <span class="link_name">Vendas</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/vendas"> <li><a class="link_name" href="#">Vendas</a></li> </router-link>
                </ul>
            </li> -->

            <li v-if="ativaMenu(2, user.menus)">
                <router-link to="/orcamentos">
                    <i class='fa fa-file-text-o ' ></i>
                    <span class="link_name">Orçamentos</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/orcamentos"> <li><a class="link_name" href="#">Orçamentos</a></li> </router-link>
                </ul>
            </li>

            <li v-if="ativaMenu(3, user.menus)">
                <router-link to="/contratos/contratos">
                    <i class='fa fa-gavel' ></i>
                    <span class="link_name">Contratos</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/contratos/contratos"> <li><a class="link_name" href="#">Contratos</a></li> </router-link>
                </ul>
            </li>           

            <!-- <li>
                <div class="iocn-link">
                    <a href="#" class="menu-title">
                        <i class='fa fa-gavel' ></i>
                        <span class="link_name">Contratos</span>
                    </a>
                    <i class='fa fa-chevron-down arrow' ></i>
                </div>
                <ul class="sub-menu">
                    <li><a class="link_name" href="#">Contratos</a></li>
                    <li><router-link to="/contratos/contratos">Cadastro de Contratos</router-link></li>
                    <li><router-link to="/contratos/gerarFaturas">Gerar Faturas</router-link></li>
                    <li><router-link to="/contratos/gerarNotas">Gerar Notas</router-link></li>
                </ul>
            </li> -->

            <li v-if="ativaMenu(5, user.menus)">
                <router-link to="/notasEmitidas">
                    <i class='fa fa-file-code-o' ></i>
                    <span class="link_name">Notas Emitidas</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/notasEmitidas"> <li><a class="link_name" href="#">Notas Emitidas</a></li> </router-link>
                </ul>
            </li>

            <li v-if="ativaMenu(6, user.menus)                 
                || ativaMenu(7, user.menus) 
                || ativaMenu(8, user.menus) 
                || ativaMenu(9, user.menus) 
                || ativaMenu(10, user.menus) 
                || ativaMenu(11, user.menus) 
                || ativaMenu(12, user.menus) 
                || ativaMenu(13, user.menus) 
                || ativaMenu(14, user.menus) 
                || ativaMenu(15, user.menus)"
            >
                <div class="iocn-link">
                    <a href="#" class="menu-title">
                        <i class='fa fa-usd' ></i>
                        <span class="link_name">Financeiro</span>
                    </a>
                    <i class='fa fa-chevron-down arrow' ></i>
                </div>
                <ul class="sub-menu">
                    <li><a class="link_name" href="#">Financeiro</a></li>
                    <li v-if="ativaMenu(6, user.menus)"><router-link to="/financeiro/caixa">Caixa</router-link></li>
                    <li v-if="ativaMenu(7, user.menus)"><router-link to="/financeiro/faturasPagar">Faturas a Pagar</router-link></li>
                    <li v-if="ativaMenu(8, user.menus)"><router-link to="/financeiro/faturasReceber">Faturas a Receber</router-link></li>
                    <li v-if="ativaMenu(9, user.menus)"><router-link to="/financeiro/formasCondicoes">Formas & Condições</router-link></li>
                    <li v-if="ativaMenu(10, user.menus)"><router-link to="/financeiro/planoDeContas">Plano de Contas</router-link></li>

                    <hr v-if="ativaMenu(11, user.menus) || ativaMenu(12, user.menus) || ativaMenu(13, user.menus)" class="separador">

                    <li v-if="ativaMenu(11, user.menus)"><router-link to="/financeiro/contasBancarias">Contas Bancárias</router-link></li>
                    <li v-if="ativaMenu(12, user.menus)"><router-link to="/financeiro/conciliacaoBancaria">Conciliações Bancárias</router-link></li>
                    <li v-if="ativaMenu(13, user.menus)"><router-link to="/financeiro/boletos">Boletos</router-link></li>
                    
                    <hr  v-if="ativaMenu(14, user.menus) || ativaMenu(15, user.menus)" class="separador">

                    <li v-if="ativaMenu(14, user.menus)"><router-link to="/financeiro/cartoes">Cartões</router-link></li>
                    <li v-if="user.admin === 'S' && ativaMenu(15, user.menus)"><router-link to="/financeiro/bancos">Bancos</router-link></li>
                </ul>
            </li>

            <li v-if="ativaMenu(16, user.menus) || ativaMenu(17, user.menus) || ativaMenu(18, user.menus)">
                <div class="iocn-link">
                    <a href="#" class="menu-title">
                        <i class='fa fa-line-chart' ></i>
                        <span class="link_name">Relatórios</span>
                    </a>
                    <i class='fa fa-chevron-down arrow' ></i>
                </div>
                <ul class="sub-menu">
                    <li><a class="link_name" href="#">Relatórios</a></li>
                    <li v-if="ativaMenu(16, user.menus)"><router-link to="/fluxodecaixa">Fluxo de Caixa</router-link></li>
                    <li v-if="ativaMenu(17, user.menus)"><router-link to="/projecao">Projeção Financeira</router-link></li>
                    <li v-if="ativaMenu(18, user.menus)"><router-link to="/dre">DRE</router-link></li>
                </ul>
            </li>

            <li v-if="ativaMenu(19, user.menus) || ativaMenu(20, user.menus) || ativaMenu(21, user.menus) || ativaMenu(22, user.menus)">
                <div class="iocn-link">
                    <a href="#" class="menu-title">
                        <i class='fa fa-address-book' ></i>
                        <span class="link_name">Pessoas</span>
                    </a>
                    <i class='fa fa-chevron-down arrow' ></i>
                </div>
                <ul class="sub-menu">
                    <li><a class="link_name" href="#">Pessoas</a></li>
                    <li v-if="ativaMenu(19, user.menus)"><router-link to="/pessoas">Cadastro de Pessoas</router-link></li>
                    <li v-if="ativaMenu(20, user.menus)"><router-link to="/pessoas/cidades">Cidades</router-link></li>
                    <li v-if="user.admin === 'S' && ativaMenu(21, user.menus)"><router-link to="/pessoas/estados">Estados</router-link></li>
                    <li v-if="user.admin === 'S' && ativaMenu(22, user.menus)"><router-link to="/pessoas/paises">Países</router-link></li>
                </ul>
            </li>

            <!-- <li v-if="ativaMenu(23, user.menus) || ativaMenu(24, user.menus) || ativaMenu(25, user.menus) || ativaMenu(26, user.menus)">
                <div class="iocn-link">
                    <a href="#" class="menu-title">
                        <i class='fa fa-cubes' ></i>
                        <span class="link_name">Produtos</span>
                    </a>
                    <i class='fa fa-chevron-down arrow'></i>
                </div>
                <ul class="sub-menu">
                    <li><a class="link_name" href="#">Produtos</a></li>
                    <li v-if="ativaMenu(23, user.menus)"><router-link to="/produtos">Cadastros de Produtos</router-link></li>
                    <li v-if="ativaMenu(24, user.menus)"><router-link to="/produtos/grupos">Grupos de produtos</router-link></li>
                    <li v-if="ativaMenu(25, user.menus)"><router-link to="/produtos/unidades">Unidades de medida</router-link></li>
                    <li v-if="user.admin === 'S' && ativaMenu(26, user.menus)"><router-link to="/produtos/gruposFiscais">Grupos fiscais</router-link></li>
                </ul>
            </li>              -->

            <li  v-if="ativaMenu(27, user.menus)">
                <router-link to="/servicos">
                    <i class='fa fa-wrench' ></i>
                    <span class="link_name">Serviços</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/servicos"> <li><a class="link_name" href="#">Serviços</a></li> </router-link>
                </ul>
            </li>

            <li v-if="user.admin === 'S' && ativaMenu(28, user.menus)">
                <router-link to="/empresas">
                    <i class='fa fa-building' ></i>
                    <span class="link_name">Empresas</span>
                </router-link>
                <ul class="sub-menu blank">
                    <router-link to="/empresas"> <li><a class="link_name" href="#">Empresas</a></li> </router-link>
                </ul>
            </li> 

            <li v-if="ativaMenu(29, user.menus) || ativaMenu(30, user.menus) || ativaMenu(31, user.menus) || ativaMenu(32, user.menus) || ativaMenu(33, user.menus)">
                <div class="iocn-link">
                    <a href="#" class="menu-title">
                        <i class='fa fa-cog' ></i>
                        <span class="link_name">Configurações</span>
                    </a>
                    <i class='fa fa-chevron-down arrow'></i>
                </div>
                <ul class="sub-menu">
                    <li><a class="link_name" href="#">Configurações</a></li>
                    <li  v-if="ativaMenu(29, user.menus)"><router-link to="/config">Parâmetros</router-link></li>
                    <li  v-if="ativaMenu(33, user.menus)"><router-link to="/ferramentas">Ferramentas</router-link></li>
                    <li v-if="ativaMenu(30, user.menus)"><router-link to="/notasfiscais">Notas Fiscais</router-link></li>
                    <li v-if="ativaMenu(31, user.menus)"><router-link to="/tributacoes">Tributações</router-link></li>
                    <li v-if="ativaMenu(32, user.menus)"><router-link to="/modelos">Modelos de Relatório</router-link></li>
                </ul>
            </li>

            <div style="height: 50px">
            </div>          
        </ul>         
    </aside>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'Menu',
        props: {
            hideToggle: Boolean,
            hideUserDropdown: Boolean
        },        
        computed: 
            mapState(['isMenuVisible', 'isMenuCompact', 'user']),         
        methods: {
            chamaPlanos() {
                const url = 'https://rexskale.com/nossos-planos/';
                
                const win = window.open(url, '_blank')
                win.focus()
            },
            ativaMenu(id, menus) {                
                const perm = menus.filter((p) => p.id_permissao === id)

                if (perm.length > 0) { 
                    return perm[0].e_permitido === 'S' ? true : false
                } else {
                    return true
                }                 
            },
            Menuzinho() {  
                let arrow = document.querySelectorAll(".arrow");
                let menuTitle = document.querySelectorAll(".menu-title");

                for (var i = 0; i < arrow.length; i++) {
                    arrow[i].addEventListener("click", (e)=>{
                        let arrowParent = e.target.parentElement.parentElement;
                        // eslint-disable-next-line no-console
                        console.log(arrowParent)
                        arrowParent.classList.toggle("showMenu");
                    });                    
                }

                for (var c = 0; c < menuTitle.length; c++) {
                    menuTitle[c].addEventListener("click", (e)=>{
                        let menuTitleParent = e.target.parentElement.parentElement.parentElement;
                        // eslint-disable-next-line no-console
                        //console.log('Aquiii!', menuTitle)
                        menuTitleParent.classList.toggle("showMenu");
                    });
                }
            },
            toggleMenu() {
                this.$store.commit('toggleMenu')            
            }
        },
        mounted() {
            this.Menuzinho()
        }
    }   
</script>

<style>
    .plano {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
        font-size: 22px !important;
        color: #ec971f !important;
        margin-top: 15px !important;
        transition: all 0.4s !important;
        white-space: nowrap !important;
    }

    .tog-plan {
       margin-bottom: 10px !important;
    }

    .tog-plan:hover {
        background: linear-gradient(to right, #37356b, #343269) !important;
    }        

    .tog-plan span {
        margin-top: 5px;
    }

    .toggle {
        width: 78px;
        height: 78px;
        color: rgb(102, 101, 101);
        text-decoration: none;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .toggle:hover {
        color: rgb(255, 253, 253);
        text-decoration: none;
        transition-duration: 500ms;
    }

    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    .sidebar {
        grid-area: menu; 
        height: 100%;
        width: 250px;
        background: linear-gradient(to right, #37356b, #343269);
        /* background: linear-gradient(to right, #323a4b, #293241); */
        z-index: 100;
        transition: all 0.5s ease;

        position: fixed;
        top: 70px;
        left: 0;

    }

    .sidebar.closeup {
        width: 78px;
    }

    .sidebar .nav-links {
        height: 100%;
        padding: 00px 0 50px 0;
        overflow: auto;
    }

    .sidebar.closeup .nav-links {
        overflow: visible;
    }

    .sidebar .nav-links::-webkit-scrollbar {
        display: none;
    }

    .sidebar .nav-links li {
        position: relative;
        list-style: none;
        transition: all 0.4s ease;
    }

    .sidebar .nav-links li:hover {
        background: #25244d;
    }

    .sidebar .nav-links li .iocn-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .sidebar.closeup .nav-links li .iocn-link {
        display: block
    }

    .sidebar .nav-links li i {
        height: 50px;
        min-width: 78px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .sidebar .nav-links li i.arrow{
        font-size: 12px;   
    }

    .sidebar .nav-links li.showMenu i.arrow {
        transform: rotate(-180deg);
    }
    .sidebar.closeup .nav-links i.arrow {
        display: none;
    }
    .sidebar .nav-links li a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .sidebar .nav-links li a .link_name {
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        transition: all 0.4s ease;
    }

    .sidebar.closeup .nav-links li a .link_name {
        opacity: 0;
        pointer-events: none;
    }

    .sidebar .nav-links li .sub-menu {
        padding: 6px 6px 14px 80px;
        margin-top: -10px;
        background: #25244d;
        display: none;
    }

    .sidebar .nav-links li.showMenu .sub-menu {
        display: block;
    }

    .sidebar .nav-links li .sub-menu a {
        color: #fff;
        font-size: 15px;
        padding: 5px 0;
        white-space: nowrap;
        opacity: 0.6;
        transition: all 0.3s ease;
    }

    .sidebar .nav-links li .sub-menu a:hover {
        opacity: 1;
    }

    .sidebar.closeup .nav-links li .sub-menu {
        position: absolute;
        left: 100%;
        top: -10px;
        margin-top: 0;
        padding: 10px 20px;
        border-radius: 0 6px 6px 0;
        opacity: 0;
        display: block;
        pointer-events: none;
        transition: 0s;
    }

    .sidebar.closeup .nav-links li:hover .sub-menu {
        top: 0;
        opacity: 1;
        pointer-events: auto;
        transition: all 0.4s ease;
    }

    .sidebar .nav-links li .sub-menu .link_name {
        display: none;
    }

    .sidebar.closeup .nav-links li .sub-menu .link_name {
        font-size: 18px;
        opacity: 1;
        display: block;
    }

    .sidebar .nav-links li .sub-menu.blank {
        opacity: 1;
        pointer-events: auto;
        padding: 3px 20px 6px 16px;
        opacity: 0;
        pointer-events: none;
    }

    .sidebar .nav-links li:hover .sub-menu.blank {
        top: 50%;
        transform: translateY(-50%);
    }

    @media (max-width: 420px) {
        .sidebar.closeup .nav-links li .sub-menu {
            display: none;
        }
    }

    .separador {
        margin: 5px 0 5px 0;
        border-color: #c7c7c73d;
        width: 30px;
    }
</style>