<template>
    <div class="ferramentas-pages">
        <PageTitle icon="fa fa-wrench" main="Ferramentas"
            sub="Importações de planilhas" />
        
        <Ferramentas />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import Ferramentas from './Ferramentas.vue'

export default {
    name: 'FerramentasPages',
    components: { PageTitle, Ferramentas }
}
</script>

<style>

</style>
