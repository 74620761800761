<template>
    <div class="cadastro-fa-wrench">
        <b-card class="cadastros-card-pesquisa" style="min-height: 500px" v-show="show">
            <b-row>
                <b-col md="4" sm="12" class="cadastros-pesquisa-coluna">
                    <b-card class="card-importar card-importar-p" title="Importar Pessoas" sub-title="Importar cadastros a partir de planilha no formato CSV, separadas por ponto e vírgula (;).">                       
                        <b-form id="frm-pessoas" @submit.prevent="onSubmit('imptr-pessoas')">
                            <div class="mb-3">
                                <a :href="linkModeloPessoa">Baixar a Planilha Modelo</a>
                            </div>
                            <br>
                            
                            <b-form-group>
                                <b-form-file
                                    class="c-file-input fa"
                                    id="csv-file-p"
                                    v-model="csvFileP"
                                    :state="fileStateP"
                                    browse-text=""
                                    placeholder="Selecione um arquivo CSV"
                                    accept=".csv"
                                    required
                                ></b-form-file>
                            </b-form-group>

                            <b-button variant="primary" type="submit" :disabled="isUploading">Importar</b-button>

                            <b-spinner v-if="isUploading" label="Carregando..." class="ml-2"></b-spinner>
                        </b-form>
                       </b-card>                     
                </b-col> 

                <b-col md="4" sm="12" class="cadastros-pesquisa-coluna">
                    <b-card class="card-importar card-importar-fp" title="Importar Faturas a Pagar" sub-title="Importar faturas a partir de planilha no formato CSV, separadas por ponto e vírgula (;).">
                        <b-form id="frm-fatp" @submit.prevent="onSubmit('imptr-fp')">
                            <div class="mb-3">
                                <a :href="linkModeloFP">Baixar a Planilha Modelo</a>
                            </div>
                            <br>
                            
                            <b-form-group>
                                <b-form-file
                                    class="c-file-input fa"
                                    id="csv-file-fp"
                                    v-model="csvFileFP"
                                    :state="fileStateFP"
                                    browse-text=""
                                    placeholder="Selecione um arquivo CSV"
                                    accept=".csv"
                                    required
                                ></b-form-file>
                            </b-form-group>

                            <b-button variant="primary" type="submit" :disabled="isUploading">Importar</b-button>

                            <b-spinner v-if="isUploading" label="Carregando..." class="ml-2"></b-spinner>
                        </b-form>
                    </b-card>                     
                </b-col> 

                <b-col md="4" sm="12" class="cadastros-pesquisa-coluna">
                    <b-card class="card-importar card-importar-fr" title="Importar Faturas a Receber" sub-title="Importar faturas a partir de planilha no formato CSV, separadas por ponto e vírgula (;).">
                        <b-form id="frm-fatrs" @submit.prevent="onSubmit('imptr-fr')">
                            <div class="mb-3">
                                <a :href="linkModeloFR">Baixar a Planilha Modelo</a>
                            </div>
                            <br>
                            
                            <b-form-group>
                                <b-form-file
                                    class="c-file-input fa"
                                    id="csv-file-fr"
                                    v-model="csvFileFR"
                                    :state="fileStateFR"
                                    browse-text=""
                                    placeholder="Selecione um arquivo CSV"
                                    accept=".csv"
                                    required
                                ></b-form-file>
                            </b-form-group>

                            <b-button variant="primary" type="submit" :disabled="isUploading">Importar</b-button>

                            <b-spinner v-if="isUploading" label="Carregando..." class="ml-2"></b-spinner>
                        </b-form>
                    </b-card>                     
                </b-col> 
            </b-row>
            
        </b-card>   

        <b-modal 
            v-model="showModalImportar"
            id="modal-importar" 
            centered  
            :title="modalTitulo"
        >
            <b-row>
                <b-col>
                    <span style="font-size: 1.2rem">{{statusSucesso}}</span>                    
                    <br>
                    <div v-if="statusErro">
                        <hr>
                        <div  style="margin-bottom: 5px;">
                            <span style="font-size: 1.2rem"><strong>Erros</strong></span>  
                        </div>

                        <div  v-for="(erro, index) in statusErro" :key="index" style="margin-bottom: 5px;">
                            {{ erro }}
                        </div>
                    </div>

                </b-col>
            </b-row>            

            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        style="margin-right: 10px"
                        variant="success"
                        size="md"
                        class="float-right"
                        @click="showModalImportar = false"
                    >
                        OK
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess} from '@/global'
import axios from 'axios'
import 'font-awesome/css/font-awesome.min.css';

export default {
    name: 'CadastroConfig',
    computed: {
        fileStateP() {
            return this.csvFileP ? true : null;
        },
        fileStateFR() {
            return this.csvFileFR ? true : null;
        },
        fileStateFP() {
            return this.csvFileFP ? true : null;
        },
        linkModeloPessoa() {
            return '/planilhasModelos/modeloPessoas.csv'
        },
        linkModeloFP() {
            return '/planilhasModelos/faturasPagar.csv'
        },
        linkModeloFR() {
            return '/planilhasModelos/faturasReceber.csv'
        },
    },
    data: function() {
        return {
            showModalImportar: false,
            modalTitulo: 'Importando...',
            urlFinal: '',
            statusSucesso: 'Iniciando importação de dados...',
            statusErro: '',
            lote: 1,

            csvFileP: null,
            csvFileFR: null,
            csvFileFP: null,

            isUploading: false, // Controla o estado de upload
            message: '', // Mensagem de feedback para o usuário
            messageVariant: 'info', // Cor da mensagem (info, success, danger, etc.)
            mode: 'save',
            filiais: [],
            show: true, 
            planoContas: []
        }
    },  
    methods: { 
        loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
            })
            .catch(err => {
                showError(err)
            }) 
        },
        loadPlanoContas() {
            const url = `${baseApiUrl}/plano_contas/?tipo=R`
            axios.get(url).then(res => {
                this.planoContas = res.data.map(planoConta => {
                    return { ...planoConta, value: planoConta.id_plano_conta, text: `${planoConta.id_plano_conta} | ${planoConta.descricao}` }
                })
            })
        },
        
        async onSubmit(form) { 
            
            let file = null
            
            switch (form) {
                case 'imptr-pessoas':
                    if (!this.csvFileP || this.csvFileP.length === 0) return;
                    file = this.csvFileP
                    this.modalTitulo = 'Importando Pessoas...'                    
                    this.urlFinal = 'importpessoas'                    
                    break;
                case 'imptr-fp':
                    if (!this.csvFileFP || this.csvFileFP.length === 0) return;
                    file = this.csvFileFP
                    this.modalTitulo = 'Importando Faturas a Pagar...' 
                    this.urlFinal = 'importfp'                    
                    break;
                case 'imptr-fr':
                    if (!this.csvFileFR || this.csvFileFR.length === 0) return;
                    file = this.csvFileFR
                    this.modalTitulo = 'Importando Faturas a Receber...'  
                    this.urlFinal = 'importfr'                   
                    break;
            }
            
            const reader = new FileReader();

            if (file && file.type !== 'text/csv') {
                this.message = 'Por favor, carregue um arquivo CSV.';
                this.messageVariant = 'danger';
                return;
            }

            reader.onload = async (e) => {
                const text = e.target.result;

                // 2. Processar o arquivo CSV
                const records = this.parseCSV(text);

                // 3. Enviar os dados para a API
                await this.uploadDataInBatches(records);
            };  

            // 4. Lê o arquivo como texto
            reader.readAsText(file); // Agora estamos passando um único arquivo para o FileReader
        },

        // Função para processar o arquivo CSV
        parseCSV(csvText) {
            const lines = csvText.split('\n');
            const headers = lines[1].split(';');  // Considerando separador ";"
            const records = [];

            for (let i = 2; i < lines.length -1; i++) {
                const values = lines[i].split(';');
                const record = {};

                headers.forEach((header, index) => {
                record[header.trim()] = values[index] ? values[index].trim() : '';
                });

                records.push(record);
            }

            return records;
        },

        // Função para enviar os dados em lotes de 100 para a API
        async uploadDataInBatches(data) {
            this.isUploading = true
            this.tamanho = data.length
            this.message = '';  // Limpar qualquer mensagem anterior

            const batchSize = 100;
            const batches = [];

            // Dividir os dados em lotes de 100 registros
            for (let i = 0; i < data.length; i += batchSize) {
                batches.push(data.slice(i, i + batchSize));
            }

            this.lote = 1

            try {
                for (const batch of batches) { 
                    const sucesso = await this.sendBatchToAPI(batch);
                    if (!sucesso) {
                        // Se o envio falhar, sai do loop
                        break;
                    }

                    this.lote = this.lote + 1
                }

                this.message = 'Dados enviados com sucesso!';
                this.messageVariant = 'success';
            } catch (error) {
                showError(error);
                this.message = 'Erro ao enviar os dados.';
                this.messageVariant = 'danger';
            } finally {
                this.isUploading = false;
            }
        },

        // Função para enviar um lote de dados para a API
        async sendBatchToAPI(batch) {
            this.showModalImportar = true;

            try {
                await axios['post'](`${baseApiUrl}/ferramentas/${this.urlFinal}`, batch);

                if (this.tamanho > (this.lote * 100)) {
                    this.statusSucesso = `Enviado com sucesso até a linha ${(this.lote * 100) + 2}`;
                }

                if (this.tamanho <= (this.lote * 100)) {
                    this.statusSucesso = `Todos os registros foram enviados com sucesso.`;
                    showSuccess(`Todos os registros foram enviados com sucesso.`);
                }

                return true;  // Retorna true para indicar sucesso
            } catch (err) {

                let msgs = ''

                if(err && err.response && err.response.data) {
                    msgs = err.response.data
                } else if(typeof err === 'string') {
                    msgs = err
                } else {
                    msgs = 'Erro desconhecido!'
                }
                
                this.statusErro = msgs ? msgs.split('|').map(item => item.trim()) : [];

                showError(err);

                return false;  // Retorna false em caso de erro
            }
        },

    },
    mounted() {
        this.loadPlanoContas(),
        this.loadFiliais()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .c-file-input .custom-file-label::after {
        content: "\f0ee";  /* Código do ícone Font Awesome (ícone de upload) */
        font-family: 'FontAwesome'; /* Fonte do Font Awesome */
        font-weight: 900; 
    }

    .card-importar {
        
        padding: 0 0 40px 0; 
    }

    .card-content {
        display: flex;
        width: 100% !important;
    }

    .card-importar::before {
        font-family: 'FontAwesome'; /* Certifique-se de usar a família correta */
        font-weight: 900; /* Para ícones sólidos */
        position: absolute;
        bottom: 0;
        right: 0;
        width: 25%; /* Ocupa 1/4 do card */
        height: 25%; /* Faz o ícone ser quadrado (1/4 do card) */  
        margin: 0 0 20px 0;      
        
        display: flex;
        align-items: center;
        font-size: 5rem; /* Ajuste o tamanho do ícone conforme necessário */
    }

    .card-importar-p::before {
        content: "\f2c0";
        color: rgb(164, 186, 255); /* Cor do ícone */
    }
    .card-importar-fp::before {
        content: "\f155";
        color: rgba(255, 164, 164, 0.767); /* Cor do ícone */
    }
    .card-importar-fr::before {
        content: "\f155";
        color: rgba(61, 182, 81, 0.753); /* Cor do ícone */
    }





</style>